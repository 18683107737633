<template>
  <v-dialog v-model="displayAttachmentDialogVisibility" max-width="850px" @click:outside="closeDialog">
    <v-card>
      <v-card-title>
        Preview of {{ this.local_attachment['filename'] }}
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <img alt="attachment image" :src="this.local_attachment['data']" width="100%">
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-layout class="flex flex-row justify-center">
            <v-btn color="blue darken-1" text @click="closeDialog">CLOSE</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DisplayAttachmentDialog",
  data() {
    return {
      displayAttachmentDialogVisibility: false,
      local_attachment: null
    }
  },
  props: {
    visible: {
      type: Boolean
    },
    attachment: {
      type: Object
    }
  },
  methods: {
    closeDialog() {
      this.displayAttachmentDialogVisibility = false
      this.$emit('close_display_attachment_dialog')
    }
  },
  beforeMount() {
    this.local_attachment = this.attachment
    // console.log("1-- " + this.local_attachment)
  },
  mounted() {
    this.displayAttachmentDialogVisibility = this.visible
    // this.local_attachment = this.attachment
    // console.log("1-- " + this.local_attachment)
  }
}
</script>

<style scoped>

</style>