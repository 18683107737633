<template>
  <v-container>
    <v-layout justify-center>
      <h3>{{ label }} {{ showSideLoadState() }}</h3>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="pink" @click="showConductsDialog" :disabled="!isAdmin" v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-plus-box-multiple-outline</v-icon>
          </v-btn>
        </template>
        <span>Add multiples conducts on this side</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="pink" @click="showConductDialog" :disabled="!isAdmin" v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-plus-box-outline</v-icon>
          </v-btn>
        </template>
        <span>Add one conduct on this side</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="pink" @click="copySideStructureDialog" :disabled="!isAdmin" v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Copy this structure to the other sides</span>
      </v-tooltip>
      <v-dialog v-model="conductsDialog" max-width="750">
        <v-card>
          <v-card-title>Add new conduct(s) for {{ this.label }}</v-card-title>
          <v-card-text>
            <v-container>
              <v-layout justify-center class="red white--text">
                <h4>!! This operation will DELETE existing conducts for this side !!</h4>
              </v-layout>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field label="How many conducts on the X axe?" v-model="conducts_on_x" type="number" min="1"
                                max="9"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="How many conducts on the Y axe?" v-model="conducts_on_y" type="number" min="1"
                                max="9"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-switch label="Is this/these conduct(s) reserved?" v-model="conduct_reserved" inset
                            color="info"></v-switch>
                </v-col>
                <v-col cols="12" v-if="conduct_reserved">
                  <v-textarea clearable clear-icon="mdi-close-circle" label="Text" v-model="reserved_text"
                              filled></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-if="isErrorAddingConducts">
              <v-layout justify-center class="red white--text">
                <h3>!! {{  this.errorAddingConductsMsg }} !!</h3>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelAddConductsToSide">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="addConductsToSide">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="conductDialog" max-width="750">
        <v-card>
          <v-card-title>Add one conduct for {{ this.label }}</v-card-title>
          <v-card-text>
            <v-container>
              <v-layout justify-center class="red white--text">
                <h4>This operation will add one conduct on this side</h4>
              </v-layout>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field label="Position X" v-model="position_x" type="number" min="1" max="9"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Position Y" v-model="position_y" type="number" min="1" max="9"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-switch label="Is this conduct reserved?" v-model="one_conduct_reserved" inset
                            color="info"></v-switch>
                </v-col>
                <v-col cols="12" v-if="one_conduct_reserved">
                  <v-textarea clearable clear-icon="mdi-close-circle" label="Text" v-model="one_reserved_text"
                              filled></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-if="isErrorAddingOneConduct">
              <v-layout justify-center class="red white--text">
                <h3>!! {{  this.errorAddingOneConductMsg }} !!</h3>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelAddConductToSide">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="addConductToSide">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="copySideDialog" max-width="750">
        <v-card>
          <v-card-title>Copy the structure of {{ this.label }} to the other sides</v-card-title>
          <v-card-text>
            <v-container>
              <v-layout justify-center class="red white--text">
                <h4>!! This operation will REPLACE existing conducts of the targeted sides !!</h4>
              </v-layout>
            </v-container>
            <v-container>
              <v-layout>
                <v-row>
                  <v-col cols="12">
                    <v-select v-model="selectedCopySides" :items="listCopySides" chips
                              label="Select the targeted side(s)" multiple :menu-props="{ maxHeight: '400'}" clearable>
                    </v-select>
                  </v-col>
                </v-row>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelCopySideStructure">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="copySideStructure">Copy</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-treeview :items="ducts_['items']" item-key="_id['$oid']" dense item-children="items" return-object>
      <template v-slot:prepend="{ item }">
                        <span v-if="item.ttype==='conduct' && isDuctReserved(item)">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="isDuctReserved(item)" color="red" v-on="on" v-bind="attrs">mdi-exclamation</v-icon>
          </template>
          <p>This duct is reserved!</p>
          <p>{{ item.reserved.comment }}</p>
        </v-tooltip>
        </span>

      </template>
      <template v-slot:label="{ item }">
        {{ showLabel(item) }} {{ showLoadState(item) }}

        <span v-if="item.ttype==='conduct'">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if='item.ttype==="conduct"' icon color="green" @click="editConduct(item)"
                           :disabled="!isAdmin" v-on="on" v-bind="attrs">
                        <v-icon>mdi-playlist-edit</v-icon>
                      </v-btn>
          </template>
          <p>Edition of this conduct</p>
          <p>(diameter={{ getConductDiameter() }} mms)</p>
        </v-tooltip>
        </span>

        <span v-if='item.ttype==="conduct" || item.item_type.is_duct'>
          <v-btn icon color="green" @click="addNewItem(item)" :disabled="!isAdmin">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </span>
        <span>
          <v-btn icon color="pink" @click="removeItem(item)" :disabled="!isAdmin">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </span>

        <span v-if='item.ttype==="item"'>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" icon color="blue">
                <v-icon v-on="on" v-bind="attrs">mdi-information-outline</v-icon>
              </v-btn>
            </template>
              <div>
                <p>Category: {{ item.item_type.category }}</p>
                <p>Color: {{ item.color }}</p>
                <p>Label: {{ item.label }}</p>
                <p>Purpose: {{ showPurposeDetails(item) }}</p>
                <p>Owner: {{ showOwnerDetails(item) }}</p>
                <p>User: {{ showUserDetails(item) }}</p>
                <p>Diameter: {{ showDiameter(item) }} mms</p>
                <p>Is duct: {{ showIsDuct(item) }}</p>
              </div>
          </v-tooltip>
        </span>

      </template>
    </v-treeview>
    <v-dialog v-model="dialog" max-width="650px">
      <v-card>
        <v-card-title class="text-h5">Add new item(s) here</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-select :items="items" item-text="label" v-model="selectedItemsToAdd"
                        label="Select the item(s) to add here" @change="setSelectedItemsForAdding"
                        :menu-props="{ maxHeight: '400' }" return-object clearable>
                <template v-slot:item="{ item }">
                  {{ item.label }} ( {{ item.item_type.name }} / {{ item.item_type.category }} )
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.label }}
                </template>
              </v-select>
            </v-row>
            <v-row v-show="tooManyItem">
              <v-col cols="12">
                <h3>This item is already in this manhole 2 times!!</h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="addSelectedItemToManhole"
                 :disabled="noItemsSelected || tooManyItem">ADD
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-conduct-dialog v-if="getEditConductDialogVisibility"
                         v-bind:conduct="selectedConduct"
                         v-bind:visible="getEditConductDialogVisibility"
                         v-on:close_edit_conduct_dialog="closeEditConductDialog"/>

  </v-container>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/consts";
import {v4 as uuidv4} from 'uuid'
import traverse from 'turbo-traverse'
import EditConductDialog from "@/components/dialogs/EditConductDialog";
import { sort_conducts } from "@/views/utils";

export default {
  name: "SideComponent",
  components: {
    'edit-conduct-dialog': EditConductDialog
  },
  data() {
    return {
      conductDialog: false,
      conductsDialog: false,
      copySideDialog: false,
      one_conduct_reserved: false,
      one_reserved_text: '',
      conduct_reserved: false,
      reserved_text: '',
      dialog: false,
      conducts_on_x: 1,
      conducts_on_y: 1,
      ducts_: [],
      defaultManhole_: {},
      position_x: 1,
      position_y: 1,
      selectedItemsToAdd: null,
      itemSelectedForAdd: {},
      cableToBeInserted: '',
      items: [],            // list of items from DB
      alreadySelectedItems_: [],       // list of _id of already selected items (items in the manholes)
      uuidOfSelectedItem: '',
      selectedCopySides: [],
      listCopySides: [],
      isEditConductDialog: false,
      selectedConduct: null,
      numberOfItems: 0,
      tooManyItemCheck: false,
      errorAddingOneConduct: false,
      errorAddingOneConductMsg: '',
      errorAddingConducts : false,
      errorAddingConductsMsg: ''
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.IS_ADMIN
    },
    noItemsSelected() {
      return this.selectedItemsToAdd === null
    },
    getEditConductDialogVisibility() {
      return this.isEditConductDialog
    },
    tooManyItem() {
      return this.tooManyItemCheck
    },
    isErrorAddingOneConduct() {
      return this.errorAddingOneConduct
    },
    isErrorAddingConducts() {
      return this.errorAddingConducts
    }
  },
  props: {
    defaultManhole: {
      type: Object,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    refreshside: {
      type: Function,
      required: false
    }
  },
  methods: {
    getConductDiameter() {
      return this.$store.getters.COMMON_VALUES['conduct_diameter']
    },
    closeDialog() {
      // console.log('Cancel add item!!')
      this.uuidOfSelectedItem = ''
      this.selectedItemsToAdd = null
      this.cableToBeInserted = ''
      this.tooManyItemCheck = false
      this.dialog = false
    },
    addNewItem(item) {
      this.uuidOfSelectedItem = item.uuid
//      console.log('uuid of selected item where to add new items --> ' + this.uuidOfSelectedItem)
      this.dialog = true
    },
    removeItem(item) {
//      console.log("remove item --> " + JSON.stringify(item))
      // item to remove is a conduct
      // rearrange the number of the conducts
      if (item.ttype === 'conduct') {
        // console.log('conduct to remove (x,y) --> (' + item.position_x + "," + item.position_y + ")")
        // let y = item.position_y
        for (let i = 0; i < this.ducts_['items'].length; i++) {   // array of the conducts for this side
          if (this.ducts_['items'][i]['position_x'] === item.position_x && this.ducts_['items'][i]['position_y'] === item.position_y) {
            this.ducts_['items'].splice(i, 1)   // remove the conduct
          }
        }
        // rename the conducts
        this.rearrangeConductNumbers(this.ducts_['items'])
      }
      if (item.ttype === 'item') {
        this.uuidOfSelectedItem = item.uuid
//        console.log("uuid of the item to be removed --> " + this.uuidOfSelectedItem)
        let that = this
        let path = null
        traverse(this.ducts_['items']).map(function (o) {
          if (o['uuid'] === that.uuidOfSelectedItem) {
            path = this.path
          }
        })
        if (path !== null) {      // length = 3 ou 5 ou 7 (3 niveaux)
          // console.log('path: ' + path)
          switch (path.length) {
            case 3:
              // console.log(JSON.stringify(this.ducts_['items'][path[0]]['items'][path[2]]))
              this.ducts_['items'][path[0]]['items'].splice(path[2], 1)
              // console.log(JSON.stringify(this.ducts_['items']))
              break
            case 5:
              this.ducts_['items'][path[0]]['items'][path[2]]['items'].splice(path[4], 1)
              break
            case 7:
              this.ducts_['items'][path[0]]['items'][path[2]]['items'][path[4]]['items'].splice(path[6], 1)
              break
            default:
              break
          }
          console.log(JSON.stringify(this.ducts_['items'][path[0]]['uuid']))
          this.computeLoadStateForAConduct(this.ducts_['items'][path[0]]['uuid'])
        }
      }
      this.computeLoadStateForSide()
      this.computeLoadStateForManhole()
    },
    addSelectedItemToManhole() {
      let that = this
      // console.log('default manhole: ' + JSON.stringify(this.defaultManhole))
      // let conduct = {}
      traverse(that.ducts_['items']).forEach((o) => {
        if (o['uuid'] === that.uuidOfSelectedItem) {
          // add the items here
          // for (let i = 0; i < that.selectedItemsToAdd.length; i++) {
          let item = {
            "_id": {
              "$oid": that.selectedItemsToAdd['_id']['$oid']
            },
            "uuid": uuidv4(),
            "item_type": that.selectedItemsToAdd['item_type'],
            "color": that.selectedItemsToAdd['color'],
            "label": that.selectedItemsToAdd['label'],
            "owner": that.selectedItemsToAdd['owner'],
            "purpose": that.selectedItemsToAdd['purpose'],
            "user": that.selectedItemsToAdd['user'],
            "ttype": 'item'
          }
          if (that.selectedItemsToAdd['item_type']['is_duct']) item['items'] = []
          o['items'].push(item)
          // }
        }
      })
      that = null
      this.defaultManhole['estimated'] = false
      // calculate the load state for this side

//      this.computeLoadStateForAConduct(this.ducts_['items'])
      this.computeLoadStateForAConduct(this.uuidOfSelectedItem)
      this.computeLoadStateForSide()
      this.computeLoadStateForManhole()
      this.selectedItemsToAdd = null
      this.closeDialog()
    },
    showLabel(item) {
      if (item.position_x === undefined) {
        if (item.ttype === 'item') {
          const label = item.label + ' (' + item.item_type.category + ') '
          if (label.length > 30) return label.substring(0, 30) + '...'
          else return label
          // return item.label + ' (' + item.item_type.category + ') '
        }
      } else return item.position_x + "-" + item.position_y
    },
    showLoadState(item) {
      if (item.position_x !== undefined) {
        let value = item.load_state
        let i = Number.parseFloat(value)
        return ' (' + i.toFixed(5) + ')'
      } else return ''
    },
    showSideLoadState() {
      let value = this.ducts_['load_state']
      if (Number.isNaN(value)) value = 0.0
      return ' (' + Number.parseFloat(value).toFixed(5) + ')'
    },
    showConductsDialog() {
      this.conductsDialog = true
    },
    showConductDialog() {
      this.errorAddingOneConductMsg = ''
      this.errorAddingOneConduct = false
      this.conductDialog = true
    },
    cancelAddConductsToSide() {
      this.conduct_reserved = false
      this.reserved_text = ''
      this.conducts_on_x = 1
      this.conducts_on_y = 1
      this.conductsDialog = false
    },
    cancelAddConductToSide() {
      this.position_x = 1
      this.position_y = 1
      this.one_conduct_reserved = false
      this.one_reserved_text = ''
      this.conductDialog = false
    },
    addConductsToSide() {
      // add conduct(s) to the current side
      // and remove the existing conducts!!
      // conduct number to add on the X axes --> this.conducts_on_x
      // conduct number to add on the Y axes --> this.conducts_on_y
      if (this.conducts_on_x <1 || this.conducts_on_x > 9
          || this.conducts_on_y <1 || this.conducts_on_y > 9) {
        this.errorAddingConductsMsg = "Invalid number for this matrix (should be between 1 and 9)"
        this.errorAddingConducts = true
        console.warn('Invalid number for this matrix (should be between 1 and 9)')
        return
      }
      this.ducts_['items'] = []
      let reserved = {      // reserved values for all new created conducts
        "status": this.conduct_reserved,
        "comment": this.reserved_text
      }
      for (let x = 1; x <= parseInt(this.conducts_on_x); x++) {
        for (let y = 1; y <= parseInt(this.conducts_on_y); y++) {
          let conduct = {
            "uuid": uuidv4(),       // unique id for this element
            "position_x": x,
            "position_y": y,
            "load_state": 0.0,
            "reserved": reserved,
            "ttype": "conduct",     // for display on the tree only !!
            "items": []
          }
          this.ducts_['items'].push(conduct)
        }
      }
      this.conduct_reserved = false
      this.reserved_text = ''
      this.conducts_on_x = 1
      this.conducts_on_y = 1
      this.conductsDialog = false
    },
    addConductToSide() {
      // console.log('add one conduct to a side')
      // console.log(' to position X: ' + this.position_x)
      // console.log('to position Y: ' + this.position_y)
      console.log('check if the conduct already exists!')
      if (parseInt(this.position_x) > 9 || parseInt(this.position_y) > 9
          || (parseInt(this.position_x) < 1 || parseInt(this.position_y) < 1)) {
        this.errorAddingOneConductMsg = "Invalid number for this conduct (should be between 1 and 9)"
        this.errorAddingOneConduct = true
        console.warn('Invalid number for this conduct (should be between 1 and 9)')
        return
      }
      for (let i = 0; i < this.ducts_['items'].length; i++) {
        if (parseInt(this.position_x) === this.ducts_['items'][i]['position_x']
            && parseInt(this.position_y) === this.ducts_['items'][i]['position_y']) {
          this.errorAddingOneConductMsg = "This conduct already exists on this side"
          this.errorAddingOneConduct = true
          console.warn('This conduct already exists in this side!!')
          return
        }
      }
      let reserved = {      // reserved values for all new created conducts
        "status": this.one_conduct_reserved,
        "comment": this.one_reserved_text
      }
      let conduct = {       // object of a new conduct
        "uuid": uuidv4(),       // unique id for this element
        "position_x": parseInt(this.position_x),
        "position_y": parseInt(this.position_y),
        "load_state": 0.0,
        "reserved": reserved,
        "ttype": "conduct",     // for display on the tree only !! Not use on the backend
        "items": []
      }
      this.ducts_['items'].push(conduct)    // add the new conduct at the end of the array
      this.ducts_['items'].sort(sort_conducts)  // sort the conducts
//      this.rearrangeConductNumbers(this.ducts_['items'])    // rearrange the numbers for the conduct
      this.conductDialog = false
    },
    setSelectedItemsForAdding(item) {
      // console.log('Items to be added here --> ' + JSON.stringify(item))
      // console.log(item['_id']['$oid'])
      this.tooManyItemCheck = false
      let that = this
      that.numberOfItems = 0;
      if (item !== undefined && item !== null) {
        // console.log(this.defaultManhole['sides'])
        traverse(this.defaultManhole['sides']).forEach((it) => {
          // console.log(JSON.stringify(item))
          if (it['_id'] !== undefined && it['_id']['$oid'] === item['_id']['$oid']) {
            that.numberOfItems += 1
            // console.log('found !!')
          }
        })
        // console.log('numberOfItems: ' + this.numberOfItems)
        that = null
        if (this.numberOfItems >= 2) {
          // console.log('oups!! already 2 instances of this item!! ')
          // console.log('selectedItemsToAdd: ' + JSON.stringify(this.selectedItemsToAdd))
          this.tooManyItemCheck = true
        } else {
          this.selectedItemsToAdd = item
          // console.log('selectedItemsToAdd: ' + JSON.stringify(this.selectedItemsToAdd))
        }
      }
    },
    loadItemsFromDB() {
      axios.get(API_BASE_URL + '/items')
          .then(response => {
            this.items = response.data
            // console.log('cables from DB loaded!')
          })
          .catch(error => {
            console.log("cables -->" + error)
          })
    },
    showPurposeDetails(item) {
      if (item.purpose === null || item.purpose === '' || item.purpose === undefined) return ""
      else return item.purpose.label
    },
    showUserDetails(item) {
      if (item.user === null || item.user === '' || item.user === undefined) return ""
      else return item.user.name
    },
    showOwnerDetails(item) {
      if (item.owner === null || item.owner === '' || item.owner === undefined) return ""
      else return item.owner.name
    },
    showIsDuct(item) {
      return item.item_type.is_duct
    },
    showDiameter(item) {
      return item.item_type.diameter
    },
    copySideStructureDialog() {
      let a = ['IN', 'OUT', 'LEFT', 'RIGHT']
      let label_ = this.label.substring(5)    // side to be copied
      // console.log('SIDE to be copied: ' + label_)
      // remove the original label
      this.listCopySides = a.filter(a => a !== label_)
      // console.log('list of sides: ' + this.listCopySides)
      this.copySideDialog = true
    },
    rearrangeConductNumbers(conducts) {
      // compute how many items for each tens
      let p = new Array(conducts.length)
      p.fill(0, 0, conducts.length)
      for (let i = 0; i < conducts.length; i++) {
        switch (conducts[i].position_x) {
          case 1:
            if (p[0] === null || p[0] === undefined) p[0] = 0
            p[0] = p[0] + 1
            break
          case 2:
            if (p[1] === null || p[1] === undefined) p[1] = 0
            p[1] = p[1] + 1
            break
          case 3:
            if (p[2] === null || p[2] === undefined) p[2] = 0
            p[2] = p[2] + 1
            break
          case 4:
            if (p[3] === null || p[3] === undefined) p[3] = 0
            p[3] = p[3] + 1
            break
          case 5:
            if (p[4] === null || p[4] === undefined) p[4] = 0
            p[4] = p[4] + 1
            break
          case 6:
            if (p[5] === null || p[5] === undefined) p[5] = 0
            p[5] = p[5] + 1
            break
          case 7:
            if (p[6] === null || p[6] === undefined) p[6] = 0
            p[6] = p[6] + 1
            break
          case 8:
            if (p[7] === null || p[7] === undefined) p[7] = 0
            p[7] = p[7] + 1
            break
          case 9:
            if (p[8] === null || p[8] === undefined) p[8] = 0
            p[8] = p[8] + 1
            break
          case 10:
            if (p[9] === null || p[9] === undefined) p[9] = 0
            p[9] = p[9] + 1
            break
          default:
            console.error('Oups!! Only 10 conducts per line are managed!!') // TODO fixme !!
            break
        }
      }
      // now we have the numbers of (1,X), (2,X), (3,X), .... , (10,X)
      // remove the 0 values
      p = p.filter((item) => {
        return item !== 0
      })
      // now rename the position (x,y)
      // now rename the position x et and position y accordingly
      let k = 0
      for (let i = 0; i < p.length; i++) {
        let x = i + 1
        for (let j = 0; j < p[i]; j++) {
          let y = j + 1
          // h[k] = {x,y}
          this.ducts_['items'][k]['position_x'] = x
          this.ducts_['items'][k]['position_y'] = y
          k++
        }
      }
    },
    cancelCopySideStructure() {
      this.copySideDialog = false
    },
    copySideStructure() {
      // console.log('copy the structure to targeted sides')
      // console.log(JSON.stringify(this.selectedCopySides))
      let label_ = this.label.substring(5)
      for (let i = 0; i < this.selectedCopySides.length; i++) {
        let side_ = this.generateCopySide(label_, this.selectedCopySides[i])
        // replace the old structure by the new one
        for (let j = 0; j < this.defaultManhole_['sides'].length; j++) {
          if (this.defaultManhole_['sides'][j]['label'] === this.selectedCopySides[i]) {
            this.defaultManhole_['sides'][j] = side_
            // console.log(JSON.stringify(this.defaultManhole_['sides'][j]))
            break
          }
        }
        // console.log(JSON.stringify(side_))
      }
      this.refreshside()
      // console.log('manhole after: ' + JSON.stringify(this.defaultManhole_))
      this.copySideDialog = false
    },
    generateCopySide(label, new_label) {
      let new_side = []
      for (let i = 0; i < this.defaultManhole_['sides'].length; i++) {
        if (this.defaultManhole_['sides'][i]['label'] === label) {
          new_side = JSON.parse(JSON.stringify(this.defaultManhole_['sides'][i]))   // make a copy not a ref
        }
      }
      new_side['label'] = new_label
      new_side['load_state'] = 0.0
      for (let j = 0; j < new_side['items'].length; j++) {  // go through the conducts of this side
        // for each conduct, remove the items & update the uuid
        new_side['items'][j]['items'] = []
        new_side['items'][j]['uuid'] = uuidv4()
      }
      return new_side
    },
    computeLoadStateForAConduct(conductUUID) {
      const conduct_area = Math.PI * (Math.pow(this.$store.getters.COMMON_VALUES['conduct_diameter'], 2) / 4)
      const that = this
      traverse(that.ducts_['items']).forEach((o) => {
        if (o['uuid'] === conductUUID) {
          // console.log('found!!')
          // console.log(JSON.stringify(o))
          // compute the new load state
          let items_area = 0.0
          for (let i = 0; i < o['items'].length; i++) {
            const item_diameter = Number.parseFloat(o['items'][i]['item_type']['diameter'])
            const item_area = Math.PI * (Math.pow(item_diameter, 2) / 4)
            items_area += item_area
          }
          o['load_state'] = items_area / conduct_area
          // console.log('conduct ratio: ' + o['load_state'])
        }
      })
      // console.log(this.ducts_['items'])
    },
    computeLoadStateForSide() {
      // console.log('side ' + JSON.stringify(this.ducts_['items']))
      let load_load_state = 0.0
      for (let i = 0; i < this.ducts_['items'].length; i++) {
        load_load_state = load_load_state + parseFloat(this.ducts_['items'][i]['load_state'])
      }
      let value = (load_load_state / this.ducts_['items'].length)   // average computation
      this.ducts_['load_state'] = value
    },
    computeLoadStateForManhole() {
      let local_load_state = 0.0
      for (let i = 0; i < this.defaultManhole['sides'].length; i++) {
        local_load_state = local_load_state + parseFloat(this.defaultManhole['sides'][i]['load_state'])
      }
      let value = (local_load_state / 4)
      if (value > 1) value = 1
      if (value < 0) value = 0
      this.defaultManhole['load_state'] = value
    },
    editConduct(item) {
      // console.log('edit conduct: ' + JSON.stringify(item))
      this.selectedConduct = item
      // console.log('selected conduct: ' + JSON.stringify(this.selectedConduct))
      this.isEditConductDialog = true
      // console.log('isEditConductDialog: ' + this.isEditConductDialog)
    },
    closeEditConductDialog() {
      this.isEditConductDialog = false
      this.selectedConduct = null
      // console.log(JSON.stringify(this.ducts_))
    },
    isDuctReserved(item) {
      if (item !== undefined) {
        return item.reserved.status
      } else return false
    }
  },
  mounted() {
    this.defaultManhole_ = this.defaultManhole    // only for copy side structures
    if (this.label === 'SIDE IN') this.ducts_ = this.defaultManhole_['sides'][0]
    if (this.label === 'SIDE LEFT') this.ducts_ = this.defaultManhole_['sides'][2]
    if (this.label === 'SIDE RIGHT') this.ducts_ = this.defaultManhole_['sides'][3]
    if (this.label === 'SIDE OUT') this.ducts_ = this.defaultManhole_['sides'][1]
    this.loadItemsFromDB()
  }
}
</script>

<style scoped>

</style>