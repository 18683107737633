<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-column justify-center">
      <p class="ma-0 pa-0 text-body-2 font-weight-thin">You are using the development version (v1.0). Use it this caution!</p>
      <h1 class="text-center">Infrastructure Data Management Tool</h1>
    </div>
    <div v-if="isAuthenticated" class="d-flex flex-row justify-end">
      <span class="user_text">Welcome, {{ this.$store.getters.USER_LOGGED.name }}</span>
      <span class="user_text"><v-btn small outlined color="blue-grey" @click="logout">LOGOUT</v-btn></span>
    </div>
    <div class="d-flex flex-row">
      <!--      SIDE MENU -->
      <div>
        <h3 class="ml-2">Home</h3>
        <ul class="pa-2 ml-3">
          <li class="menu-item" :class="{'menu-item-active': activeMenus[0]}" @click="setActiveMenu(0)">
            Map
          </li>
        </ul>
        <h3 class="ml-2">Data Management</h3>
        <ul class="pa-2 ml-3">
          <li class="menu-item" :class="{'menu-item-active': activeMenus[1]}" @click="setActiveMenu(1)">
            Manholes
          </li>
          <li class="menu-item" :class="{'menu-item-active': activeMenus[2]}" @click="setActiveMenu(2)">
            Items
          </li>
          <li class="menu-item" :class="{'menu-item-active': activeMenus[3]}" @click="setActiveMenu(3)">
            Item Types
          </li>
          <li class="menu-item" :class="{'menu-item-active': activeMenus[4]}" @click="setActiveMenu(4)">
            Purposes
          </li>
          <li class="menu-item" :class="{'menu-item-active': activeMenus[5]}" @click="setActiveMenu(5)">
            Obstacles
          </li>
          <li class="menu-item" :class="{'menu-item-active': activeMenus[6]}" @click="setActiveMenu(6)">
            Owners
          </li>
          <li class="menu-item" :class="{'menu-item-active': activeMenus[7]}" @click="setActiveMenu(7)">
            Users
          </li>
          <li class="menu-item" :class="{'menu-item-active': activeMenus[8]}" @click="setActiveMenu(8)">
            Highways & Directions
          </li>
        </ul>
        <div v-if="isAdmin">
          <h3 class="ml-2">Accounts Management</h3>
          <ul class="pa-2 ml-3">
            <li class="menu-item" :class="{'menu-item-active': activeMenus[9]}" @click="setActiveMenu(9)">
              Accounts
            </li>
          </ul>
        </div>
        <h3 class="ml-2">Tools</h3>
        <ul class="pa-2 ml-3">
          <li class="menu-item" :class="{'menu-item-active': activeMenus[10]}" @click="setActiveMenu(10)">
            Paths
          </li>
           <li v-if="isAdmin" class="menu-item" :class="{'menu-item-active': activeMenus[11]}" @click="setActiveMenu(11)">
              GPS Import
            </li>
        </ul>
      </div>
      <!--      MAIN CONTENT -->
      <div class="flex-grow-1">
        <component :is="activeComponent"/>
      </div>
    </div>

  </div>
</template>

<script>
import MapComponent from "@/components/MapComponent";
import ManholesComponent from "@/components/ManholesComponent";
import ManholeDetailsComponent2 from "@/components/ManholeDetailsComponent2";
import ManholeCreationComponent from "@/components/ManholeCreationComponent";
import ItemsComponent from "@/components/ItemsComponent";
import ItemTypesComponent from "@/components/ItemTypesComponent";
import PurposesComponent from "@/components/PurposesComponent";
import ObstaclesComponent from "@/components/ObstaclesComponent";
import OwnersComponent from "@/components/OwnersComponent";
import HighwaysComponent from "@/components/HighwaysComponent";
import UsersComponent from "@/components/UsersComponent";
import AccountComponent from "@/components/AccountComponent";
import PathComponent from "@/components/PathComponent";
import GPSImportComponent from "@/components/GPSImportComponent";

export default {
  name: "Landingpage",
  components: {
    'map-component': MapComponent,
    'manholes-component': ManholesComponent,
    'manhole-details-2-component': ManholeDetailsComponent2,
    'manhole-creation-component': ManholeCreationComponent,
    'items-component': ItemsComponent,
    'item-types-component': ItemTypesComponent,
    'purposes-component': PurposesComponent,
    'obstacles-component': ObstaclesComponent,
    'owners-component': OwnersComponent,
    'highways-component': HighwaysComponent,
    'users-component': UsersComponent,
    'accounts-component': AccountComponent,
    'path-component': PathComponent,
    'gps-import-component': GPSImportComponent
  },
  data() {
    return {
      activeMenus: [],        // dynamic array of boolean for managing the selection of the side menu
      activeMenu: ''          // store the index of the menu that is active
    }
  },
  computed: {
    activeComponent() {
      return this.$store.getters.ACTIVE_COMPONENT
    },
    isAuthenticated() {
      return this.$store.getters.IS_AUTHENTICATED
    },
    isAdmin() {
      return this.$store.getters.IS_ADMIN
    }
  },
  methods: {
    setActiveMenu(index) {
      if (this.activeMenu === '') {     // no menu active --> at the beginning
        this.$set(this.activeMenus, index, true)
      } else {
        this.$set(this.activeMenus, index, true)        // set to true the selected menu
        this.$set(this.activeMenus, this.activeMenu, false)   // reset the old active menu
      }
      this.activeMenu = index // store the new active menu
      // console.log('index --> ' + index)
      switch (index) {
        case 0:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'map-component')
          break
        case 1:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'manholes-component')
          break
        case 2:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'items-component')
          break
        case 3:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'item-types-component')
          break
        case 4:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'purposes-component')
          break
        case 5:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'obstacles-component')
          break
        case 6:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'owners-component')
          break
        case 7:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'users-component')
          break
        case 8:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'highways-component')
          break
        case 9:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'accounts-component')
          break
        case 10:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'path-component')
          break
        case 11:
          this.$store.commit('SET_ACTIVE_COMPONENT', 'gps-import-component')
          break
      }
    },
    logout() {
      this.$store.commit('SET_MANHOLES_FROM_DB_LOADED', false)
      this.$store.commit('SET_MANHOLES_FROM_DB', [])
      this.$store.commit('SET_ITEM_TYPES_FROM_DB_LOADED', false)
      this.$store.commit('SET_ITEMS_FROM_DB_LOADED', false)
      this.$store.commit('SET_OBSTACLES_FROM_DB_LOADED', false)
      this.$store.commit('SET_OWNERS_FROM_DB_LOADED', false)
      this.$store.commit('SET_USERS_FROM_DB_LOADED', false)
      this.$store.commit('SET_PURPOSES_FROM_DB_LOADED', false)
      this.$store.commit('SET_HIGHWAYS_FROM_DB_LOADED', false)
      this.$store.commit('SET_USER_LOGGED', {})
      this.$store.commit('SET_IS_AUTHENTICATED', false)
      this.$store.commit('SET_IS_ADMIN', false)
      this.$store.commit('SET_ACTIVE_COMPONENT', '')
      this.$router.push('/login')
    }
  },
  mounted() {
    for (let i = 0; i < 12; i++) {
      this.activeMenus[i] = false
    }
    this.$store.dispatch('loadCommonValues')
  }

}
</script>

<style scoped>

</style>