<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-center">
      <h2>Creation of a new Manhole</h2>
    </div>
    <div>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        <div class="d-flex flex-column align-center">
          <span>{{ snackbar.text }}</span>
          <span>{{ snackbar.error }}</span>
        </div>
      </v-snackbar>
    </div>
    <v-container fluid>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="5">
          <v-text-field outlined disabled label="Name" v-model="manholeFullName"></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-text-field ref="txtPK" label="PK" hint="PK should be an integer!" color="red" dense v-model="defaultManhole.pk" type="number">
            <template v-slot:label>
              PK<span class="red--text"><strong>*</strong></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select :items="directions" label="Select the direction" color="red" item-text="code" dense @change="getSelectedDirection" return-object v-model="selectedDirection">
            <template v-slot:item="{item}">
              {{ item.code }}
            </template>
            <template v-slot:selection="{item}">
              {{ item.code }}
            </template>
            <template v-slot:label>
              Select the direction<span class="red--text"><strong>*</strong></span>
            </template>
          </v-select>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10">
          <v-text-field label="State" color="red" dense v-model="defaultManhole.state"></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-container>
        <v-row>
          <v-col cols="4"><v-checkbox
              v-model="defaultManhole.estimated"
              label="Estimated load state?"
              hide-details
              dense
          ></v-checkbox>
          </v-col>
          <v-col v-if="defaultManhole.estimated" cols="5">
            <v-text-field label="Load state (estimated)" color="red" v-model="defaultManhole.load_state" dense hint="between 0 and 1" required type="number">
              <template v-slot:label>
                Load state (estimated)<span class="red--text"><strong>*</strong></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col v-else cols="5">
            <v-text-field label="Load state (computed)" v-model="formatLoadState" dense disabled hint="between 0 and 1"></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-container>
    <v-container>
      <v-layout justify-end>
        <v-btn class="ma-2" color="primary" depressed @click="cancelSaveManhole">Cancel</v-btn>
        <v-btn class="ma-2" color="primary" depressed @click="saveManhole" :disabled="PKIsNaNumber || PKNull || PKIsNaInteger || selectedDirectionIsNull" :loading="isSaving">Save</v-btn>
      </v-layout>
    </v-container>
    <v-container>
      <v-expansion-panels>
        <v-expansion-panel :disabled="false">
          <v-expansion-panel-header>
            <div v-if="isSidesEmpty" class="d-flex flex-row align-center">
              <v-icon color="error">mdi-information</v-icon>
              SIDES
            </div>
            <div v-else class="d-flex flex-row align-center">
              <v-icon color="success">mdi-information</v-icon>
              SIDES
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="4">
                  <div class="d-flex justify-center">
<!--                    <side label="SIDE IN" v-bind:conducts="defaultManhole.sides[0]" :defaultManhole="defaultManhole"></side>-->
                    <side label="SIDE IN" :key="sideInRefKey" v-bind:defaultManhole="defaultManhole" :refreshside="forceSideInRender"></side>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <div class="d-flex justify-center">
<!--                    <side label="SIDE LEFT" v-bind:conducts="defaultManhole.sides[2]" :defaultManhole="defaultManhole"></side>-->
                    <side label="SIDE LEFT" :key="sideLeftRefKey" v-bind:defaultManhole="defaultManhole" :refreshside="forceSideInRender"></side>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4">
                  <div class="d-flex justify-center">
<!--                    <side label="SIDE RIGHT" v-bind:conducts="defaultManhole.sides[3]" :defaultManhole="defaultManhole"></side>-->
                    <side label="SIDE RIGHT" :key="sideRightRefKey" v-bind:defaultManhole="defaultManhole" :refreshside="forceSideInRender"></side>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="4">
                  <div class="d-flex justify-center">
<!--                    <side label="SIDE OUT" v-bind:conducts="defaultManhole.sides[1]" :defaultManhole="defaultManhole"></side>-->
                    <side label="SIDE OUT" :key="sideOutRefKey" v-bind:defaultManhole="defaultManhole" :refreshside="forceSideInRender"></side>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div v-if="isObstaclesEmpty" class="d-flex flex-row align-center">
              <v-icon color="error">mdi-information</v-icon>
              OBSTACLES
            </div>
            <div v-else class="d-flex flex-row align-center">
              <v-icon color="success">mdi-information</v-icon>
              OBSTACLES
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="6">
                <v-select :items="obstacles" label="Select obstacle before this manhole" color="red" item-text="type" dense clearable return-object
                @change="getObstacleBefore">
                  <template v-slot:item="{item}">
                    {{ item.type }}
                  </template>
                  <template v-slot:selection="{item}">
                    {{ item.type }}
                  </template>
                </v-select>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select :items="obstacles" label="Select obstacle after this manhole" color="red" item-text="type" dense clearable return-object
                @change="getObstacleAfter">
                  <template v-slot:item="{item}">
                    {{ item.type }}
                  </template>
                  <template v-slot:selection="{item}">
                    {{ item.type }}
                  </template>
                </v-select>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div v-if="isVisitsEmpty" class="d-flex flex-row align-center">
              <v-icon color="error">mdi-information</v-icon>
              VISITS
            </div>
            <div v-else class="d-flex flex-row align-center">
              <v-icon color="success">mdi-information</v-icon>
              VISITS
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-layout justify-end>
              <v-btn class="ma-2" color="primary" @click="showAddVisitDialog">
                New visit
              </v-btn>
            </v-layout>
            <v-data-table v-model="selectedVisit" :headers="visitsHeaders" :items="visits"
                          item-key="id" :items-per-page="5">
              <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    @click="deleteVisit(item)"
                >
                  mdi-delete
                </v-icon>
              </template>

            </v-data-table>
            <v-dialog v-model="addVisitDialog" max-width="750px">
              <v-card>
                <v-card-title class="text-h5">New visit</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                    <v-date-picker v-model="datePicker" @change="getSelectedDate"></v-date-picker>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="6">
                    <v-textarea label="Comment" v-model="visitComment" filled></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancelAddVisit">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="saveAddVisit">ADD</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div v-if="isAttachmentsEmpty" class="d-flex flex-row align-center">
              <v-icon color="error">mdi-information</v-icon>
              ATTACHMENTS
            </div>
            <div v-else class="d-flex flex-row align-center">
              <v-icon color="success">mdi-information</v-icon>
              ATTACHMENTS
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-layout justify-end>
              <v-btn class="ma-2" color="primary" @click="showAddAttachmentDialog">
                New attachment
              </v-btn>
            </v-layout>
            <v-data-table v-model="selectedAttachments" :headers="attachmentsHeaders" :items="attachments"
                          item-key="id" :items-per-page="5">
              <template v-slot:item.actions="{ item, index }">
                <v-icon class="ma-2"
                    small
                    @click="displayAttachment(index)"
                >
                  mdi-eye-outline
                </v-icon>
                <v-icon class="ma-2"
                    small
                    @click="deleteAttachment(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <v-dialog v-model="addAttachmentDialog" max-width="750px">
              <v-card>
                <v-card-title class="text-h5">New attachment</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-file-input v-model="attachment"
                        accept="image/*"
                        label="File input (only images)"
                        prepend-icon="mdi-camera" :value="attachmentFiles"
                    ></v-file-input>
                  </v-row>
                  <v-row>
                    <v-text-field label="comment" v-model="attachmentComment"></v-text-field>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancelAddAttachment">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="saveAddAttachment">ADD</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>CROSS-HIGHWAY MANHOLES</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-combobox
                    v-model="connected_manholes"
                    :items="cross_manholes"
                    :item-text = "getItemText"
                    label="Select the manhole(s) that is/are the connection with this manhole"
                    multiple small-chips dense>
                </v-combobox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <display-attachment v-if="getIsDisplayAttachmentDialogVisible"
            v-bind:visible="getIsDisplayAttachmentDialogVisible" v-bind:attachment="this.attachmentToDisplay"
            v-on:close_display_attachment_dialog="closeDisplayAttachmentDialog">
      </display-attachment>
    </v-container>

  </div>
</template>

<script>
import SideComponent from "@/components/SideComponent";
import axios from "axios";
import {API_BASE_URL} from "@/consts";
import traverse from 'turbo-traverse'
import DisplayAttachmentDialog from "@/components/dialogs/DisplayAttachmentDialog";
export default {
  name: "ManholeCreationComponent",
  components: {
    'side': SideComponent,
    'display-attachment': DisplayAttachmentDialog
  },
  data() {
    return {
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
      selectedDirection: '',
      defaultManhole: {
        "name": '',
        "direction_id": "",
        "pk": "",
        "latitude": "",
        "longitude": "",
        "state": "",
        "load_state": 0.5,
        "estimated": true,
        "obstacles": {
          "before": '',
          "after": ''
        },
        "visits": [],
        "attachments": [],
        "sides": [
          {
            "label": "IN",
            "load_state": 0.0,
            "items": []
          },
          {
            "label": "OUT",
            "load_state": 0.0,
            "items": []
          },
          {
            "label": "LEFT",
            "load_state": 0.0,
            "items": []
          },
          {
            "label": "RIGHT",
            "load_state": 0.0,
            "items": []
          }
        ],
        "highway_bridge": []
      },
      directions: [],
      obstacles: [],
      obstacle: {
        "before": '',
        "after": ''
      },
      selectedVisit: [],
      visits: [],
      visitsHeaders: [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date'
        },
        {
          text: 'Comment',
          align: 'start',
          sortable: false,
          value: 'comment'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ],
      addVisitDialog: false,
      addAttachmentDialog: false,
      selectedAttachments: [],
      attachmentsHeaders: [
        {
          text: 'Filename',
          align: 'start',
          sortable: true,
          value: 'filename'
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ],
      attachments: [],
      datePicker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      visitComment: '',
      attachment: null,             // File object added to attachment
      attachmentFiles: [],          // list of File objects
      attachmentComment: '',
      connected_manholes: [],
      cross_manholes: [],
      index_for_key: 0,
      sideInRefKey: 0,
      sideOutRefKey: 0,
      sideLeftRefKey: 0,
      sideRightRefKey: 0,
      isDisplayAttachmentDialogVisible: false,
      attachmentToDisplay: null,
      savingManhole: false
    }
  },
  computed: {
    PKNull() {
      return this.defaultManhole.pk === ''
    },
    PKIsNaNumber() {
      return Number.isNaN(Number(this.defaultManhole.pk))
    },
    PKIsNaInteger() {
      return !Number.isInteger(Number(this.defaultManhole.pk))
    },
    isAdmin() {
      return this.$store.getters.IS_ADMIN
    },
    selectedDirectionIsNull() {
      return this.selectedDirection===''
    },
    manholeFullName: {
      // getter
      get: function() {
        if (this.selectedDirection.highway === undefined) return ''
        else return this.selectedDirection.highway.name + "-" + this.selectedDirection.code + "-" + this.defaultManhole.pk
      }
    },
    formatLoadState: {
      get: function () {
        return Number.parseFloat(this.defaultManhole['load_state']).toFixed(5)
      }
    },
    isSidesEmpty() {
      return (this.defaultManhole.sides[0].items.length===0 && this.defaultManhole.sides[1].items.length===0 &&
          this.defaultManhole.sides[2].items.length===0 && this.defaultManhole.sides[3].items.length===0)
    },
    isObstaclesEmpty() {
      return (this.defaultManhole.obstacles.before==="" && this.defaultManhole.obstacles.after==="")
    },
    isVisitsEmpty() {
      return this.defaultManhole.visits.length<1
    },
    isAttachmentsEmpty() {
      return this.defaultManhole.attachments.length<1
    },
    getIsDisplayAttachmentDialogVisible() {
      return this.isDisplayAttachmentDialogVisible
    },
    isSaving() {
      return this.savingManhole
    }
  },
  methods: {
    getSelectedDirection(item) {
//      console.log('direction id --> ' + JSON.stringify(item['_id']['$oid']))
      this.defaultManhole['direction_id'] = item['_id']['$oid']
    },
    saveManhole() {
      console.log('save manhole')
      // console.log('default manhole (before modification) --> ' + JSON.stringify(this.defaultManhole))
      let manhole_to_save =JSON.parse(JSON.stringify(this.defaultManhole))  // make a copy of the Manhole object
      // console.log('manhole_to_save --> ' + JSON.stringify(manhole_to_save))
      manhole_to_save.name = this.manholeFullName
      let obstacles = {
        "before": this.getObstacleService('before', this.defaultManhole),
        "after": this.getObstacleService('after', this.defaultManhole)
      }
      manhole_to_save['obstacles'] = obstacles
      // console.log('defaultManhole --> ' + JSON.stringify(this.defaultManhole))
//      console.log('manhole_to_save --> ' + JSON.stringify(manhole_to_save))
      // process the sides
      // SIDES management
      for (let i = 0; i < manhole_to_save['sides'].length; i++) {   // SIDES IN, OUT, LEFT and RIGHT
        for (let j=0;j< manhole_to_save['sides'][i]['items'].length; j++) {   // conduct
          traverse(manhole_to_save['sides'][i]['items']).forEach((o) => {
            if (o !== undefined && o['_id'] !== undefined &&
                o['ttype'] !== undefined && o['ttype']==='item') {    // process item uniquely (not duct)
              o['item_id'] = o['_id']['$oid']
              delete(o['_id'])
              delete(o['item_type'])
              delete(o['color'])
              delete(o['label'])
              delete(o['owner'])
              delete(o['purpose'])
              delete(o['user'])
              // console.log('o --> ' + JSON.stringify(o))
            }
          })
        }
      }
      // highways bridge
      manhole_to_save['highway_bridge'] = this.getCrossManholesServices(this.connected_manholes)
      // console.log('manhole_to_save (after modification)--> ' + JSON.stringify(manhole_to_save))
      let user_logged = this.$store.getters.USER_LOGGED
      let formData = new FormData()
      formData.append('json', JSON.stringify(manhole_to_save))
      // for (let i = 0; i < this.attachmentFiles.length; i++) {
      //   formData.append('files', this.attachmentFiles[i], this.attachments[i]['filename'])
      // }
      this.savingManhole = true
      axios.post(API_BASE_URL + '/manholes', formData, {
        headers: {"enctype": "multipart/form-data",
        "x-account": user_logged.name}
      }).then(() => {
        // this.$store.dispatch('loadManholesFromDB')
        this.savingManhole = false
        this.$store.commit('SET_MANHOLES_FROM_DB_LOADED', false)
        this.$store.commit('SET_ACTIVE_COMPONENT', 'manholes-component')
        this.snackbar.color = "green"
        this.snackbar.timeout = 2500
        this.snackbar.text = "Manhole successfully saved!"
        this.snackbar.error = ""
        this.snackbar.show = true
      }).catch(error => {
        this.savingManhole = false
        this.snackbar.color = "red"
        this.snackbar.timeout = 3500
        this.snackbar.text = "Oups! An error occurs! Manhole not saved!"
        if (error.response.data !== undefined) {
          this.snackbar.error = "( " + error.response.data['msg'] + " )"
        }
        this.snackbar.show = true
      })
    },
    getObstacleService(location, manhole) {
        if (manhole['obstacles'][location]!=="") {
          return manhole['obstacles'][location]['_id']['$oid']
        }
        else {
          return ""
        }
    },
    loadDirectionsFromDB() {
      axios.get(API_BASE_URL + '/directions')
          .then(response => {
            this.directions = response.data
          })
    },
    loadObstaclesFromDB() {
      axios.get(API_BASE_URL + '/obstacles')
          .then(response => {
            this.obstacles = response.data
          })
    },
    getObstacleBefore(item) {
      if (item===null) {
        this.defaultManhole['obstacles']['before'] = ''
      }
      else {
        this.defaultManhole['obstacles']['before'] = item
      }
      console.log(JSON.stringify(this.defaultManhole))
    },
    getObstacleAfter(item) {
      if (item===null) {
        this.defaultManhole['obstacles']['after'] = ''
      }
      else {
        this.defaultManhole['obstacles']['after'] = item
      }
      console.log(JSON.stringify(this.defaultManhole))
    },
    deleteVisit(item) {
      console.log("(delete) visit --> " + JSON.stringify(item))
      for (let i = 0; i < this.defaultManhole['visits'].length; i++) {
        if (this.defaultManhole['visits'][i].date===item.date) {
          // console.log("i --> " + i)
          this.defaultManhole['visits'].splice(i,1)
          this.visits = this.defaultManhole['visits']
          break
        }
      }
    },
    showAddVisitDialog() {
      // console.log('show addVisitDialog')
      this.datePicker = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.addVisitDialog = true
    },
    showAddAttachmentDialog() {
      this.addAttachmentDialog = true
    },
    getSelectedDate() {
      // this.datePicker = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      //console.log('selected date --> ' + this.datePicker)
    },
    cancelAddVisit() {
      this.addVisitDialog = false
      this.visitComment = ''
    },
    saveAddVisit() {
      let visit = {
        "date": this.datePicker,
        "comment": this.visitComment
      }
      this.defaultManhole['visits'].push(visit)
      this.addVisitDialog = false
      this.visitComment = ''
      this.visits = this.defaultManhole['visits']
//      console.log("manhole --> " + JSON.stringify(this.defaultManhole))
    },
    cancelAddAttachment() {
      this.attachment = null
      this.addAttachmentDialog = false
    },
    saveAddAttachment() {
      // console.log('attachment file --> ' + this.attachment)
      // console.log("file name --> " + this.attachment.name)
      let attachment = {
        "filename": this.attachment.name,
        "description": this.attachmentComment,
        "data": null
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        attachment['data'] = e.target.result
      }
      reader.readAsDataURL(this.attachment)
      // console.log("attachment data: " + attachment['data'])

      this.defaultManhole['attachments'].push(attachment)
      this.attachmentComment = ''
      // this.attachment = null
      this.attachments = this.defaultManhole['attachments']
      this.attachmentFiles.push(this.attachment)
      // console.log('1--attachment files ' + this.attachmentFiles)
      this.attachment = null
      this.addAttachmentDialog = false
    },
    deleteAttachment(item) {
      // console.log("(delete) attachment --> " + JSON.stringify(item))
      let index = -1
      // console.log('index --> ' + index)
      // console.log('item.filename --> ' + item.filename)
      for (let i=0;i<this.defaultManhole['attachments'].length;i++) {
        if (this.defaultManhole['attachments'][i].filename ===item.filename) {
          index = i
          // console.log("index --> " + index)
          break
        }
      }
      if (index > -1) {
        this.defaultManhole['attachments'].splice(index,1)
        this.attachments = this.defaultManhole['attachments']
        this.attachmentFiles.splice(index,1)
      }
    },
    cancelSaveManhole() {
      this.$store.commit('SET_ACTIVE_COMPONENT', 'manholes-component')
    },
    loadManholesFromDB() {
      axios.get(API_BASE_URL + '/manholes')
      .then(response => {
        this.cross_manholes = response.data
      })
      .catch(error => {
        console.log('ERROR --> ' + error)
      })
    },
    getItemText(item) {
      return "(" + item.direction.highway.name + " - " + item.direction.code + " - " + item.pk + ")"
    },
    getCrossManholesServices(manholes) {
      let ids = []
      for (let i = 0; i < manholes.length; i++) {
          let id = manholes[i]['_id']['$oid']
        ids.push(id)
      }
      return ids
    },
    forceSideInRender() {
      console.log('Refreshing sides')
      this.sideInRefKey += 1
      this.sideOutRefKey += 1
      this.sideLeftRefKey += 1
      this.sideRightRefKey += 1
    },
    displayAttachment(index) {
      // console.log('index: ' + index)
      this.attachmentToDisplay = this.attachments[index]
      // console.log("atatchmentToDisplay: " + this.attachmentToDisplay)
      this.isDisplayAttachmentDialogVisible = true
    },
    closeDisplayAttachmentDialog() {
      this.isDisplayAttachmentDialogVisible = false
    }
  },
  beforeMount() {
  },
  mounted() {
    this.loadDirectionsFromDB()
    this.loadObstaclesFromDB()
    this.loadManholesFromDB()
    this.$nextTick(()=>{
      const elt = this.$refs.txtPK
      setTimeout(()=>{
        elt.focus()
      })
    })
  }
}
</script>

<style scoped>

</style>