<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-center">
      <h2>Manhole Management</h2>
    </div>
    <div>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        <div class="d-flex flex-column align-center">
          <span>{{ snackbar.text }}</span>
          <span>{{ snackbar.error }}</span>
        </div>
      </v-snackbar>
    </div>
    <div>
      <v-layout justify-end>
        <v-btn class="ma-2" color="primary" @click="manholeCreation" :disabled="!isAdmin">
          New Manhole
        </v-btn>
      </v-layout>
      <v-container fluid>
        <div class="mr-2 ml-2 mt-2">
          <v-data-table v-model="selected" :headers="tableHeaders" :items="filteredManholes"
                        item-key="_id.$oid" :footer-props="footerProps" loading-text="no manholes found"
                        :items-per-page="-1" :single-select="singleSelect" show-select caption="List of manholes" loading="true">
            <template v-slot:header.direction.highway.name="{ header }">
              {{ header.text }}

              <v-menu key="indexHighwayName" offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon small color="red">
                      mdi-filter-variant
                    </v-icon>
                  </v-btn>
                </template>
                <div style="background-color: white; width: 280px">
                  <v-list>
                    <v-list-item>
                      <div v-if="filters.hasOwnProperty('highways')">
                        <v-autocomplete multiple dense clearable chips small-chips color="light-blue lighten-3" :items="columnValueListHighway()" append-icon="mdi-filter"
                                        v-model="filters['highways']" :label="filters['highways'] ? `filter_by: ${header.text}` : ''
                    " hide-details @click:clear="resetManholesFromDB">
                          <template v-slot:selection="{ item, index }">
                            <v-chip small class="caption" v-if="index < 5">
                            <span>
                              {{ item }}
                            </span>
                            </v-chip>
<!--                            <span v-if="index === 5" class="grey&#45;&#45;text caption">-->
<!--                            (+{{ filters[header.value].length - 5 }} others)-->
<!--                          </span>-->
                          </template>
                        </v-autocomplete>
                      </div>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </template>

            <template v-slot:header.direction.code="{ header }">
              {{ header.text }}

              <v-menu key="indexDirectionCode" offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon small color="red">
                      mdi-filter-variant
                    </v-icon>
                  </v-btn>
                </template>
                <div style="background-color: white; width: 280px">
                  <v-list>
                    <v-list-item>
                      <div v-if="filters.hasOwnProperty('directions')">
                        <v-autocomplete multiple dense clearable chips small-chips color="light-blue lighten-3" :items="columnValueListDirection()" append-icon="mdi-filter"
                                        v-model="filters['directions']" :label="filters['directions'] ? `filter_by: ${header.text}` : ''
                    " hide-details @click:clear="resetManholesFromDB">
                          <template v-slot:selection="{ item, index }">
                            <v-chip small class="caption" v-if="index < 5">
                            <span>
                              {{ item }}
                            </span>
                            </v-chip>
<!--                            <span v-if="index === 5" class="grey&#45;&#45;text caption">-->
<!--                            (+{{ filters[header.value].length - 5 }} others)-->
<!--                          </span>-->
                          </template>
                        </v-autocomplete>
                      </div>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </template>

            <template v-slot:header.pk="{ header }">
              {{ header.text }}

              <v-menu key="indexPK" offset-y :close-on-content-click="false" :value="pkRangeMenuIsShown">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click.prevent="clickOnPKMenu">
                    <v-icon small color="red">
                      mdi-filter-variant
                    </v-icon>
                  </v-btn>
                </template>
                <div style="background-color: white; width: 250px">
                  <v-list>
                    <v-list-item>
                      <div v-if="filters.hasOwnProperty('pk')">
                        <div>
                        <v-text-field label="Minimum PK" messages="Set the minimum value of PK" v-model="filters['pk'][0]" type="number"></v-text-field>
                        <v-text-field  class="mb-3" label="Maximum PK" messages="Set the maximum value of PK" v-model="filters['pk'][1]" type="number"></v-text-field>
                          <v-btn class="ma-2" @click.prevent="setCancelPKRange()">RESET</v-btn>
                        <v-btn class="ma-2" @click.prevent="setPKRange()">OK</v-btn>
                        </div>
                      </div>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </template>

            <template v-slot:loading>
              <v-layout class="flex flex-column justify-center align-center" v-if="getIsManholesLoading">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
                <h3 class="ma-2">Loading manholes from DB. Please wait...</h3>
              </v-layout>
            </template>
            <template v-slot:top>
              <v-dialog v-model="dialogDelete" max-width="650px">
                <v-card>
                  <v-card-title class="text-h5">Are you sure you want to DELETE this manhole?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">CANCEL</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteManholeConfirm">DELETE</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogClone" max-width="550px">
                <v-card>
                  <v-card-title class="text-h5">Are you sure you want to clone this manhole?</v-card-title>
                  <v-card-text>
                    <v-container>
                    <v-layout class="d-flex flex-column">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field label="Enter the new PK" hint="PK should be an integer!" color="red" dense type="number" v-model="clone_pk">
                            <template v-slot:label>
                              PK<span class="red--text"><strong>*</strong></span>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field label="Enter the latitude" color="red" dense type="number" v-model="clone_latitude">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field label="Enter the longitude" color="red" dense type="number" v-model="clone_longitude">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-layout>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeCloneDialog">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="cloneManholeConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.name="{ item} ">
              <span v-if="incompleteManholeData(item)">
                <v-icon small color="error">mdi-information</v-icon>
              </span>
              <span v-else>
                <v-icon small color="success">mdi-information</v-icon>
              </span>
              {{ item.name }}
            </template>
            <template v-slot:item.highway="{ item }">
              {{ item.highway.name }}
            </template>
            <template v-slot:item.direction="{ item }">
              {{ item.direction.description }}
            </template>
            <template v-slot:item.latitude="{ item }">
              {{ showLatitude(item) }}
            </template>
            <template v-slot:item.longitude="{ item }">
              {{ showLongitude(item) }}
            </template>
            <template v-slot:item.load_state="{ item }">
              <v-chip
                  :color="getLoadStateColor(item)"
                  dark
                  label
              >
                {{ getLoadState(item) }}
              </v-chip>
            </template>
            <template v-slot:item.estimated="{ item }">
              <v-chip
                  :color="setColorEstimatedValue(item)"
                  dark
                  label
              >
                {{ formatEstimatedValue(item) }}
              </v-chip>
            </template>
            <template v-slot:item.creation_info="{ item }">
              <td>
                <div>{{ item.creation_info.account }}</div>
                <div>{{ item.creation_info.date }}</div>
              </td>
            </template>
            <template v-slot:item.update_info="{ item }">
              <td>
                <div>{{ showUpdateInfoAccount(item) }}</div>
                <div>{{ showUpdateInfoDate(item) }}</div>
              </td>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                  small
                  class="mr-2"
                  @click="manholeDetails(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon :disabled="!isAdmin"
                  small
                  class="mr-2"
                  @click="deleteManhole(item)"
              >
                mdi-delete
              </v-icon>
              <v-icon :disabled="!isAdmin"
                  small
                  @click="cloneManhole(item)"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <template v-slot:foot>
              <v-btn class="ma-1" @click="exportManholes">EXPORT</v-btn>
            </template>
          </v-data-table>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/consts";
import { v4 as uuidv4 } from 'uuid'

export default {
  name: "ManholesComponent",
  data() {
    return {
      filters: { pk: [], highways : [], directions: []},
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
      dialog: false,
      dialogDelete: false,
      dialogClone: false,
      singleSelect: false,
      selected: [],
      manhole_to_delete_id: '',
      manhole_to_clone: {},
      manholesFromDB: [],     // list of manholes from DB
      tableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'PK',
          align: 'start',
          sortable: true,
          value: 'pk'
        },
        {
          text: 'Highway',
          align: 'center',
          sortable: true,
          value: 'direction.highway.name'
        },
        {
          text: 'Direction',
          align: 'center',
          sortable: true,
          value: 'direction.code'
        },
        {
          text: 'Lat',
          align: 'start',
          sortable: true,
          value: 'latitude'
        },
        {
          text: 'Lon',
          align: 'start',
          sortable: true,
          value: 'longitude'
        },
        {
          text: 'Load State',
          align: 'start',
          sortable: true,
          value: 'load_state'
        },
        {
          text: 'Estimated',
          align: 'center',
          sortable: true,
          value: 'estimated'
        },
        {
          text: 'Created',
          align: 'start',
          sortable: false,
          value: 'creation_info'
        },
        {
          text: 'Updated',
          align: 'start',
          sortable: false,
          value: 'update_info'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ],
      footerProps: {'items-per-page-options': [20, 50, 100, 500, 1000, -1]},
      clone_pk: 0,
      clone_latitude: 0.0,
      clone_longitude: 0.0,
      isManholesFromDbLoading: false,
      clickOnRange: false,
      pkRangeMenuIsShown: false
    }
  },
  computed: {
    isManholesLoadedFromDB() {
      return this.$store.getters.MANHOLES_FROM_DB_LOADED
    },
    isAdmin() {
      return this.$store.getters.IS_ADMIN
    },
    getIsManholesLoading() {
      return this.isManholesFromDbLoading
    },
    isClickOnRange() {
      return this.clickOnRange
    },
    filteredManholes() {

        if (this.filters['highways'].length>0) {
          console.log('filters with highways')
          this.getManholesFilteredByHighway(this.manholesFromDB)
        }
        if (this.filters['directions'].length>0) {
          console.log('filters with directions')
          this.getManholesFilteredByDirections(this.manholesFromDB)
        }
      if (this.filters['pk'].length>1 && this.isClickOnRange)  {
       // console.log('filters with pk')
        this.getManholesFilteredByPK(this.manholesFromDB)
      }
      return this.manholesFromDB
     }
  },
  methods: {
    formatEstimatedValue(item) {
      if (item.estimated) {
        return 'Y'
      }
      else return 'N'
    },
    clickOnPKMenu() {
      // console.log('OK')
      this.pkRangeMenuIsShown = true
    },
    setPKRange() {
      // console.log(Number.parseInt(this.filters['pk'][0]))
      // console.log(Number.parseInt(this.filters['pk'][1]))
      this.clickOnRange = true
      this.pkRangeMenuIsShown = false
    },
    setCancelPKRange() {
      this.clickOnRange = false
      this.filters['pk'] = []
      this.resetManholesFromDB()
      this.pkRangeMenuIsShown = false
    },
    columnValueListHighway() {
      let x = JSON.parse(JSON.stringify(this.$store.getters.MANHOLES_FROM_DB))
      return x.map((d) => d['direction']['highway']['name'])
    },
    columnValueListDirection() {
      return this.$store.getters.MANHOLES_FROM_DB.map( (d) => d['direction']['code'])
    },
    getManholesFilteredByHighway(manholes) {
      this.manholesFromDB = manholes.filter((manhole) => {
        for (let i = 0; i < this.filters['highways'].length; i++) {
          if (manhole['direction']['highway']['name']===this.filters['highways'][i]) return true
        }
      })
    },
    getManholesFilteredByDirections(manholes) {
      this.manholesFromDB = manholes.filter((manhole) => {
        for (let i = 0; i < this.filters['directions'].length; i++) {
          if (manhole['direction']['code']===this.filters['directions'][i]) return true
        }
      })
    },
    getManholesFilteredByPK(manholes) {
      // console.log(JSON.stringify(this.filters['pk']))
      this.manholesFromDB = manholes.filter((manhole) => {
          if (manhole['pk']>=Number.parseInt(this.filters['pk'][0]) && manhole['pk']<=Number.parseInt(this.filters['pk'][1])) {
            // console.log('FOUND!!')
            return true
          }
      })
      this.filters['pk'] = []
      // sort the results
      this.manholesFromDB.sort(this.sortManholesByPK)
      // this.showPKRangeMenu = true
    },
    sortManholesByPK(manholeA, manholeB) {
      if (manholeA['pk']<manholeB['pk']) return -1
      if (manholeA['pk']>manholeB['pk']) return 1
      return 0
    },
    incompleteManholeData(item) {
      return this.isSidesEmpty(item) && this.isObstaclesEmpty(item) && this.isVisitsEmpty(item) && this.isAttachmentsEmpty(item);
    },
    isSidesEmpty(item) {
      return (item.sides[0].items.length===0 && item.sides[1].items.length===0 &&
          item.sides[2].items.length===0 && item.sides[3].items.length===0)
    },
    isObstaclesEmpty(item) {
      return (item.obstacles.before==="" && item.obstacles.after==="")
    },
    isVisitsEmpty(item) {
      return item.visits.length<1
    },
    isAttachmentsEmpty(item) {
      return item.attachments.length<1
    },
    getLoadStateColor(item) {
      if (item.load_state === '') return "red"
      let value = parseFloat(item.load_state)
      // console.log(value)
      if (value > 0.75) return "red"
      else if (value > 0.5 && value <= 0.75) return "orange"
      else return "green"
    },
    setColorEstimatedValue(item) {
      if (item.estimated) {
        return "green"
      }
      else return "red"
    },
    getLoadState(item) {
      if (item.load_state === '') {
        return "0"
      } else return Number.parseFloat(item.load_state).toFixed(5)
    },
    cancel() {
      // console.log('cancel')
      this.close()
    },
    save() {
      // console.log('save')
      this.close()
    },
    close() {
      this.dialog = false
      // this.$nextTick(() => {
      // })
    },
    deleteManhole(item) {
      // console.log(item)
      this.manhole_to_delete_id = item['_id']['$oid']
      this.dialogDelete = true
    },
    closeDelete() {
      this.manhole_to_delete_id = ''
      this.dialogDelete = false
      this.$nextTick(() => {
      })
    },
    closeCloneDialog() {
      this.dialogClone = false
    },
    cloneManholeConfirm() {
      // console.log('manhole to clone --> ' + JSON.stringify(this.manhole_to_clone))
      let old_name = this.manhole_to_clone['name'].split("-")
      // console.log(JSON.stringify(old_name))
      let sides = this.processSlides(this.manhole_to_clone['sides'])
      let clone_manhole = {
        "direction_id": this.manhole_to_clone['direction']['_id']['$oid'],
        "pk": this.clone_pk,
        "name": old_name[0] + "-" + old_name[1] + "-" + this.clone_pk,
        "latitude": parseInt(this.clone_latitude),
        "longitude": parseInt(this.clone_longitude),
        "state": "",
        "load_state": parseFloat(0.5),
        "estimated": true,
        "obstacles": {
          "before": '',
          "after": ''
        },
        "visits": [],
        "attachments": [],
        "sides": sides,
        "highway_bridge": [],
        "creation_info": this.manhole_to_clone['creation_info']
      }
      // console.log('sides after --> ' + JSON.stringify(sides))
      // console.log('manhole cloned --> ' + JSON.stringify(clone_manhole))
      let user_logged = this.$store.getters.USER_LOGGED
      axios.post(API_BASE_URL + '/manholes/clone', clone_manhole, {
        headers: {"Content-Type": "application/json;charset=UTF-8",
        "x-account": user_logged.name}
      }).then(() => {
        // this.$store.dispatch('loadManholesFromDB')
        // this.$store.commit('SET_ACTIVE_COMPONENT', 'manholes-component')
        this.closeCloneDialog()
        this.loadManholesFromDB()
        // this.snackbar.color = "green"
        // this.snackbar.timeout = 2500
        // this.snackbar.text = "Manhole successfully cloned!"
        // this.snackbar.error = ""
        // this.snackbar.show = true
      }).catch(error => {
        console.log('error --> ' + error)
        this.closeCloneDialog()
        this.snackbar.color = "red"
        this.snackbar.timeout = 3500
        this.snackbar.text = "Oups! An error occurs! Manhole not cloned!"
        if (error.response.data !== undefined) {
          this.snackbar.error = "( " + error.response.data['msg'] + " )"
        }
        this.snackbar.show = true
      })
      this.closeCloneDialog()
    },
    processSlides(sides) {
      let sides_temp = JSON.parse(JSON.stringify(sides))
      console.log("sides_tmp before --> " + JSON.stringify(sides_temp))
      for (let i = 0; i < sides_temp.length; i++) {   // SIDES IN, OUT, LEFT and RIGHT
        sides_temp[i]['load_state'] = 0.0
        for (let j=0;j< sides_temp[i]['items'].length; j++) {   // conduct
            sides_temp[i]['items'][j]['load_state'] = 0.0
            sides_temp[i]['items'][j]['uuid'] = uuidv4()
            sides_temp[i]['items'][j]['items'] = []
        }
      }
      return sides_temp
    },
    deleteManholeConfirm() {
      // console.log('delete confirmed for this manhole!')
      axios.delete(API_BASE_URL + '/manholes/' + this.manhole_to_delete_id)
          .then(() => {
            // this.$store.dispatch('loadManholesFromDB')
            this.closeDelete()
            this.snackbar.color = "green"
            this.snackbar.timeout = 2500
            this.snackbar.text = "Manhole successfully deleted!"
            this.snackbar.error = ""
            this.snackbar.show = true
            this.loadManholesFromDB()
          })
          .catch(error => {
            this.closeDelete()
            this.snackbar.color = "red"
            this.snackbar.timeout = 3500
            this.snackbar.text = "Oups! An error occurs! Manhole not deleted!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    },
    manholeDetails(item) {
      // console.log("item.pk --> " + item.pk)
      this.$store.commit('SET_MANHOLE_EDITED', item)
      this.$store.commit('SET_ACTIVE_COMPONENT', 'manhole-details-2-component')
    },
    manholeCreation() {
      this.$store.commit('SET_ACTIVE_COMPONENT', 'manhole-creation-component')
    },
    cloneManhole(item) {
      this.manhole_to_clone = JSON.parse(JSON.stringify(item))
      // console.log('manhole to be cloned: ' + JSON.stringify(item))
      this.manhole_to_clone['pk'] = this.clone_pk;
      this.manhole_to_clone['latitude'] = this.clone_latitude
      this.manhole_to_clone['longitude'] = this.clone_longitude
      // console.log('manhole cloned: ' + JSON.stringify(this.manhole_to_clone))
      this.dialogClone = true
    },
    exportManholes() {
      // console.log("export --> " + JSON.stringify(this.selected))
      axios.post(API_BASE_URL + '/admin/export', JSON.stringify(this.selected), {
        headers: {"Content-Type": "application/json;charset=UTF-8"}
      }).then((response) => {
        // console.log('response headers --> ' + JSON.stringify(response.headers))
        // console.log('response data --> ' + response.data)
        let f = new Blob([response.data],  {type: 'text/csv'})
        let a = document.createElement('a')
        let current_date = new Date().toISOString()
        a.download = current_date + '_export_manholes.csv'
        a.href = window.URL.createObjectURL(f)
        a.click()
      }).catch(error => {
        console.log('error --> ' + error)
      })
    },
    showUpdateInfoAccount(item) {
      if (item.update_info.account === undefined) return ''
      else {
        return item.update_info.account
      }
    },
    showUpdateInfoDate(item) {
      if (item.update_info.date === undefined) return ''
      else {
        return item.update_info.date
      }
    },
    showLatitude(item) {
      if (item.latitude !== undefined) return parseFloat(item.latitude).toFixed(4)
      else return ""
    },
    showLongitude(item) {
      if (item.longitude !== undefined) return parseFloat(item.longitude).toFixed(4)
      else return ""
    },
    loadManholesFromDB() {
      this.isManholesFromDbLoading = true
      axios.get(API_BASE_URL + '/manholes')
          .then(response => {
            this.isManholesFromDbLoading = false
            this.$store.commit('SET_MANHOLES_FROM_DB', response.data)
            this.manholesFromDB = response.data
   //         console.log('manholes from DB loaded!')
            this.snackbar.color = "green"
            this.snackbar.timeout = 2500
            this.snackbar.text = "Manholes successfully loaded from datastore!"
            this.snackbar.error = ""
            this.snackbar.show = true
          })
          .catch(error => {
//            console.log("error -->" + error)
            this.manholesFromDB = []
            this.isManholesFromDbLoading = false
            this.snackbar.color = "red"
            this.snackbar.timeout = 3500
            this.snackbar.text = "Oups! An error occurs! Manhole not loaded from the datastore!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    },
    resetManholesFromDB() {
      this.isManholesFromDbLoading = true
      axios.get(API_BASE_URL + '/manholes')
          .then(response => {
            this.isManholesFromDbLoading = false
            this.$store.commit('SET_MANHOLES_FROM_DB', response.data)
            this.manholesFromDB = response.data
            //         console.log('manholes from DB loaded!')
            // this.snackbar.color = "green"
            // this.snackbar.timeout = 2500
            // this.snackbar.text = "Manholes successfully loaded from datastore!"
            // this.snackbar.error = ""
            // this.snackbar.show = true
          })
          .catch(error => {
//            console.log("error -->" + error)
            this.manholesFromDB = []
            this.isManholesFromDbLoading = false
            this.snackbar.color = "red"
            this.snackbar.timeout = 3500
            this.snackbar.text = "Oups! An error occurs! Manhole not loaded from the datasatore!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    }
  },
  mounted() {
    console.log('manhole component mounted!')
    // if (!this.isManholesLoadedFromDB) {
      this.loadManholesFromDB()
//      this.$store.dispatch('loadManholesFromDB')
//     }

  }
}
</script>

<style scoped>

</style>