<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-center">
      <h2>Item Type Management</h2>
    </div>
    <div>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        <div class="d-flex flex-column align-center">
          <span>{{ snackbar.text }}</span>
          <span>{{ snackbar.error }}</span>
        </div>
      </v-snackbar>
    </div>
    <v-container fluid>
      <div class="mr-5 ml-5 mt-2">
        <v-data-table v-model="selected" :headers="tableHeaders" :items="this.$store.getters.ITEM_TYPES_FROM_DB"
                      item-key="_id.$oid" :items-per-page="-1" caption="List of Item Type" :single-select="singleSelect" show-select :footer-props="footerProps">
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="750px">
              <template v-slot:activator="{ on, attrs }">
                <v-layout justify-end>
                  <v-btn class="ma-2" color="primary" v-bind="attrs" v-on="on" :disabled="!isAdmin">
                    New Item Type
                  </v-btn>
                </v-layout>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="5">
                        <v-text-field ref="txtItemTypeName" v-model="editedItemType.name" label="Name">
                          <template v-slot:label>
                            Name<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field v-model="editedItemType.description"
                                      label="Description"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-combobox :items="categories_from_db" item-text="label" item-value="label" outlined
                                    label="Select the category" dense v-model="editedItemType.category">
                          <template v-slot:label>
                            Select the category<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-combobox>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="editedItemType.color" label="Color" dense></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="editedItemType.diameter"
                                      label="Diameter (mm)" required type="number">
                          <template v-slot:label>
                            Diameter (mm)<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-switch
                            v-model="editedItemType.is_duct"
                            inset color="info"
                            label="Is this item a duct?">
                        </v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save" :disabled="itemTypeDiameterIsNumber || !itemTypeDiameterNotNull">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="650px">
              <v-card>
                <v-card-title class="text-h5">
                  <div class="d-flex flex-grow-1 justify-center">Are you sure you want to DELETE this type of item?</div>
                </v-card-title>
                <v-card-text>
                  <v-container fluid class="red white--text">
                    <v-layout class="flex flex-column">
                      <h3 class="text-center">!! This operation will also DELETE ALL ITEMS of this type !!</h3>
                      <h3 class="text-center">!! and will REMOVE ITEMS of this type from manholes !!</h3>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">CANCEL</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteCableTypeConfirm">DELETE</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.is_duct="{ item }">
            {{ showisDuct(item) }}
          </template>
          <template v-slot:item.creation_info="{ item }">
            <td>
              <div>{{ item.creation_info.account }}</div>
              <div>{{ item.creation_info.date }}</div>
            </td>
          </template>
          <template v-slot:item.update_info="{ item }">
            <td>
              <div>{{ item.update_info.account }}</div>
              <div>{{ item.update_info.date }}</div>
            </td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon :disabled="!isAdmin"
                small
                class="mr-2"
                @click="editCableType(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon :disabled="!isAdmin"
                small
                @click="deleteCableType(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:foot>
            <v-btn class="ma-1" @click="exportCablesTypes">EXPORT</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/consts";

export default {
  name: "ItemTypesComponent",
  data() {
    return {
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
      dialog: false,
      dialogDelete: false,
      selected: [],
      singleSelect: false,
      item_type_id: '',
      edition: false,
      editedItemType: {
        name: '',
        description: '',
        category: '',
        color: '',
        diameter: 0,
        is_duct: false
      },
      defaultItemType: {
        name: '',
        description: '',
        category: '',
        color: '',
        diameter: 0,
        is_duct: false
      },
      tableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'description'
        },
        {
          text: 'Diameter (mms)',
          align: 'start',
          sortable: true,
          value: 'diameter'
        },
        {
          text: 'Category',
          align: 'start',
          sortable: true,
          value: 'category'
        },
        {
          text: 'Duct',
          align: 'start',
          sortable: true,
          value: 'is_duct'
        },
        {
          text: 'Color',
          align: 'start',
          sortable: true,
          value: 'color'
        },
        {
          text: 'Created',
          align: 'start',
          sortable: false,
          value: 'creation_info'
        },
        {
          text: 'Updated',
          align: 'start',
          sortable: false,
          value: 'update_info'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ],
      categories_from_db: [],
      footerProps: {'items-per-page-options': [20, 50, 100, 500, 1000, -1]}
    }
  },
  computed: {
    formTitle() {
      return this.edition === false ? 'Item Type creation' : 'Item Type edition'
    },
    isItemTypeLoadedFromDB() {
      return this.$store.getters.ITEM_TYPES_FROM_DB_LOADED
    },
    itemTypeDiameterNotNull() {
      return this.editedItemType.diameter !== ''
    },
    itemTypeDiameterIsNumber() {
      return Number.isNaN(Number(this.editedItemType.diameter))
    },
    isAdmin() {
      return this.$store.getters.IS_ADMIN
    }
  },
  methods: {
    cancel() {
      console.log('cancel')
      this.edition = false
      this.close()
    },
    save() {
      let user_logged = this.$store.getters.USER_LOGGED
      if (!this.edition) {
        console.log('saving the new item type')
        console.log('edited item type --> ' + JSON.stringify(this.editedItemType))
        axios.post(API_BASE_URL + '/item-type', this.editedItemType, {
          headers: {"Content-Type": "application/json;charset=UTF-8",
            "x-account": user_logged.name}
        })
            .then(() => {
              // console.log(response.data)
              this.$store.dispatch('loadItemTypesFromDB')
              this.loadCategoriesFromDB()
              this.close()
              this.snackbar.color = "green"
              this.snackbar.timeout = 2500
              this.snackbar.text = "Item type successfully saved!"
              this.snackbar.error = ""
              this.snackbar.show = true
            })
            .catch(error => {
              // console.log('error -> ' + error)
              this.close()
              this.snackbar.color = "red"
              this.snackbar.timeout = 3500
              this.snackbar.text = "Oups! An error occurs! Item type not saved!"
              if (error.response.data !== undefined) {
                this.snackbar.error = "( " + error.response.data['msg'] + " )"
              }
              this.snackbar.show = true
            })
      } else {
        console.log('saving the edited item type')
        // console.log('cable_type_id --> ' + this.cable_type_id)
        axios.put(API_BASE_URL + '/item-type/' + this.item_type_id, this.editedItemType, {
          headers: {"Content-Type": "application/json;charset=UTF-8",
            "x-account": user_logged.name}
        })
            .then(() => {
              this.item_type_id = ''
              // console.log(response.data)
              this.edition = false
              this.$store.dispatch('loadItemTypesFromDB')
              this.loadCategoriesFromDB()
              this.close()
              this.snackbar.color = "green"
              this.snackbar.timeout = 2500
              this.snackbar.text = "Item type successfully updated!"
              this.snackbar.error = ""
              this.snackbar.show = true
            })
            .catch(error => {
              this.edition = false
              this.item_type_id = ''
              // console.log('error -> ' + error)
              this.close()
              this.snackbar.color = "red"
              this.snackbar.timeout = 3500
              this.snackbar.text = "Oups! An error occurs! Item type not updated!"
              if (error.response.data !== undefined) {
                this.snackbar.error = "( " + error.response.data['msg'] + " )"
              }
              this.snackbar.show = true
            })
      }
    },
    close() {
      this.clearEditedCableType()
      this.dialog = false
    },
    editCableType(item) {
      this.edition = true
      console.log(item)
      this.item_type_id = item['_id']['$oid']
      this.editedItemType = Object.assign({}, item)
      this.dialog = true
      this.$nextTick(()=>{
        const elt = this.$refs.txtItemTypeName
        setTimeout(()=>{
          elt.focus()
        })
      })
    },
    deleteCableType(item) {
      this.item_type_id = item['_id']['$oid']
      this.dialogDelete = true
    },
    closeDelete() {
      this.item_type_id = ''
      this.dialogDelete = false
    },
    deleteCableTypeConfirm() {
      console.log("item type id selected --> " + this.item_type_id)
      axios.delete(API_BASE_URL + '/item-type/' + this.item_type_id)
          .then(() => {
            this.item_type_id = ''
            // console.log('Cable type successfully deleted!')
            // console.log(response.data)
            this.$store.dispatch('loadItemTypesFromDB')   // reset the list of item types
            this.$store.dispatch('loadItemsFromDB')       // reset the list of item
            this.$store.dispatch('loadManholesFromDB')    // reset the list of manholes
            this.closeDelete()
            this.snackbar.color = "green"
            this.snackbar.timeout = 2500
            this.snackbar.text = "Item type successfully deleted!"
            this.snackbar.error = ""
            this.snackbar.show = true
          })
          .catch(error => {
            this.item_type_id = ''
            // console.log('error --> ' + error)
            this.closeDelete()
            this.snackbar.color = "red"
            this.snackbar.timeout = 3500
            this.snackbar.text = "Oups! An error occurs! Item type not deleted!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    },
    clearEditedCableType() {
      this.editedItemType = {
        name: '',
        description: '',
        diameter: 0,
        category: '',
        color: '',
        is_duct: false
      }
    },
    loadCategoriesFromDB() {
      axios.get(API_BASE_URL + '/item-type/categories')
      .then(response => {
        this.categories_from_db = response.data
        console.log('categories loaded from DB')
      })
      .catch(error => {
        console.log(error)
      })
    },
    exportCablesTypes() {
      // console.log("export --> " + JSON.stringify(this.selected))
      axios.post(API_BASE_URL + '/admin/export', JSON.stringify(this.selected), {
        headers: {"Content-Type": "application/json;charset=UTF-8"}
      }).then((response) => {
        // console.log('response headers --> ' + JSON.stringify(response.headers))
        // console.log('response data --> ' + response.data)
        let f = new Blob([response.data],  {type: 'text/csv'})
        let a = document.createElement('a')
        let current_date = new Date().toISOString()
        a.download = current_date + '_export_item_types.csv'
        a.href = window.URL.createObjectURL(f)
        a.click()
      }).catch(error => {
        console.log('error --> ' + error)
      })
    },
    showisDuct(item) {
      if (item.is_duct) return 'YES'
      else return 'NO'
    }
  },
  mounted() {
    if (!this.isItemTypeLoadedFromDB) {
      this.$store.dispatch('loadItemTypesFromDB')
    }
    this.loadCategoriesFromDB()
  }
}
</script>

<style scoped>

</style>