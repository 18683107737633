<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-center">
      <h2>Account List</h2>
    </div>
    <div>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        <div class="d-flex flex-column align-center">
          <span>{{ snackbar.text }}</span>
          <span>{{ snackbar.error }}</span>
        </div>
      </v-snackbar>
    </div>
    <v-container fluid>
      <div class="mr-5 ml-5 mt-2">
        <v-data-table v-model="selected" :headers="tableHeaders" :items="this.$store.getters.ACCOUNTS_FROM_DB"
                      item-key="_id.$oid"
                      :items-per-page="-1" caption="List of accounts">
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="750px">
              <template v-slot:activator="{ on, attrs }">
                <v-layout justify-end>
                  <v-btn class="ma-2" color="primary" v-bind="attrs" v-on="on">
                    New Account
                  </v-btn>
                </v-layout>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="5">
                        <v-text-field ref="txtUserName" v-model="editedUser.name" label="User name">
                        <template v-slot:label>
                          User name<span class="red--text"><strong>*</strong></span>
                        </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field v-model="editedUser.email" label="User email">
                          <template v-slot:label>
                            User email<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="editedUser.password" label="User password" messages="password should have more than 5 characters">
                          <template v-slot:label>
                            User password<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select :items="roles" v-model="editedUser.role" required
                                  @change="selectedRole">
                          <template v-slot:label>
                            Select the role<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save" :disabled="isFieldsNotOK">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this user?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteUserConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.password="{ item }">
            {{ hidePassword(item) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editUser(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteUser(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/consts";

export default {
  name: "AccountComponent",
  data() {
    return {
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
      dialog: false,
      dialogDelete: false,
      selected: [],
      user_id: '',
      edition: false,
      editedIndex: -1,
      roles: ['GUEST', 'ADMIN'],
      editedUser: {
        name: '',
        email: '',
        password: '',
        role: ''
      },
      defaultUser: {
        name: '',
        email: '',
        password: '',
        role: ''
      },
      tableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'email'
        },
        {
          text: 'Password',
          align: 'start',
          sortable: false,
          value: 'password'
        },
        {
          text: 'Role',
          align: 'start',
          sortable: true,
          value: 'role'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    formTitle() {
      return this.edition === false ? 'New Account' : 'Edit Account'
    },
    isAccountsLoadedFromDB() {
      return this.$store.getters.ACCOUNTS_FROM_DB_LOADED
    },
    isFieldsNotOK() {
      return this.editedUser.name==='' || this.editedUser.email==='' ||
          this.editedUser.password==='' || this.editedUser.role==='' ||this.editedUser.password.length<5
    }
  },
  methods: {
    cancel() {
      console.log('cancel')
      this.edition = false
      this.close()
    },
    save() {
      console.log('save')
      if (this.edition === false) {
        console.log('saving the new account')
        axios.post(API_BASE_URL + '/accounts', this.editedUser, {
          headers: {"Content-Type": "application/json;charset=UTF-8"}
        })
            .then(() => {
              // console.log(response.data)
              this.$store.dispatch('loadAccountsFromDB')
              this.close()
              this.snackbar.color = "green"
              this.snackbar.timeout = 2500
              this.snackbar.text = "Account successfully saved!"
              this.snackbar.error = ""
              this.snackbar.show = true
            })
            .catch(error => {
              // console.log('error -> ' + error)
              this.close()
              this.snackbar.color = "red"
              this.snackbar.timeout = 3500
              this.snackbar.text = "Oups! An error occurs! Account not saved!"
              if (error.response.data !== undefined) {
                this.snackbar.error = "( " + error.response.data['msg'] + " )"
              }
              this.snackbar.show = true
            })
      } else {
        // console.log('saving the edited account')
        // console.log('user_id --> ' + this.user_id)
        let user = {
          "name": this.editedUser['name'],
          "email": this.editedUser['email'],
          "password": this.editedUser['password'],
          "role": this.editedUser['role']
        }
        // console.log('user --> ' + JSON.stringify(user))
        axios.put(API_BASE_URL + '/accounts/' + this.user_id, user, {
          headers: {"Content-Type": "application/json;charset=UTF-8"}
        })
            .then(() => {
              this.user_id = ''
              // console.log(response.data)
              this.edition = false
              this.$store.dispatch('loadAccountsFromDB')
              this.close()
              this.snackbar.color = "green"
              this.snackbar.timeout = 2500
              this.snackbar.text = "Account successfully updated!"
              this.snackbar.error = ""
              this.snackbar.show = true
            })
            .catch(error => {
              this.edition = false
              this.user_id = ''
              // console.log('error -> ' + error)
              this.close()
              this.snackbar.color = "red"
              this.snackbar.timeout = 3500
              this.snackbar.text = "Oups! An error occurs! Account not updated!"
              if (error.response.data !== undefined) {
                this.snackbar.error = "( " + error.response.data['msg'] + " )"
              }
              this.snackbar.show = true
            })
      }
    },
    close() {
      this.clearEditedUser()
      this.dialog = false
    },
    editUser(item) {
      this.edition = true
      // console.log(item)
      this.user_id = item['_id']['$oid']
      this.editedUser = Object.assign({}, item)
      this.dialog = true
      this.$nextTick(()=>{
        const elt = this.$refs.txtUserName
        setTimeout(()=>{
          elt.focus()
        })
      })
    },
    deleteUser(item) {
      this.user_id = item['_id']['$oid']
      this.dialogDelete = true
    },
    closeDelete() {
      this.user_id = ''
      this.dialogDelete = false
    },
    deleteUserConfirm() {
      console.log("user id selected --> " + this.user_id)
      axios.delete(API_BASE_URL + '/accounts/' + this.user_id)
          .then(() => {
            this.user_id = ''
            console.log('account successfully deleted!')
            // console.log(response.data)
            this.$store.dispatch('loadAccountsFromDB')
            this.closeDelete()
          })
          .catch(error => {
            this.user_id = ''
            console.log('error --> ' + error)
            this.closeDelete()
          })
    },
    selectedRole() {
      // console.log('role -> ' + role)
    },
    clearEditedUser() {
      this.editedUser = {
        name: '',
        email: '',
        password: '',
        role: ''
      }
    },
    hidePassword() {
      return '**********'
    }
  },
  mounted() {
    if (!this.isUsersLoadedFromDB) {
      this.$store.dispatch('loadAccountsFromDB')
    }
  }
}
</script>

<style scoped>

</style>