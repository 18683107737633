<template>
  <v-dialog v-model="editConductDialogVisibility" max-width="750px" @click:outside="closeDialog">
    <v-card>
      <v-card-title>{{ formatTitle() }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-switch label="Is this conduct reserved?" v-model="isConductReserved" inset color="info" @change="changeReserved"></v-switch>
            </v-col>
            <v-col cols="12" v-if="isConductReserved">
              <v-textarea clearable clear-icon="mdi-close-circle" label="Text" v-model="reservedText"
                          filled></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.prevent="closeDialog">CANCEL</v-btn>
        <v-btn color="blue darken-1" text @click.prevent="updateSelectedDuct">UPDATE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditConductDialog",
  props: {
    conduct: {
      type: Object,
      required: false
    },
    visible: {
      type: Boolean
    }
  },
  data() {
    return {
      editConductDialogVisibility: false,
      conduct_: null,
      isConductReserved: false,
      reservedText: ''
    }
  },
  methods: {
    formatTitle() {
      return "Edition of the conduct (" + this.conduct_['position_x'] + "," + this.conduct_['position_y'] + ")"
    },
    closeDialog() {
      this.conduct_ = null
      this.isConductReserved = null
      this.reservedText = null
      this.editConductDialogVisibility = false
      this.$emit('close_edit_conduct_dialog')
    },
    updateSelectedDuct() {
      this.conduct_['reserved']['status'] = this.isConductReserved
      this.conduct_['reserved']['comment'] = this.reservedText
      this.conduct['reserved'] = JSON.parse(JSON.stringify(this.conduct_['reserved']))
      // console.log('conduct: ' + JSON.stringify(this.conduct))
      this.closeDialog()
    },
    changeReserved() {
      if (!this.isConductReserved) this.reservedText = ''
    }
  },
  beforeMount() {
    // console.log('conduct: ' + JSON.stringify(this.conduct))
    this.conduct_ = JSON.parse(JSON.stringify(this.conduct))   // copy the conduct
    // console.log('local conduct_: ' + JSON.stringify(this.conduct_))
    this.isConductReserved = this.conduct_['reserved']['status']
    this.reservedText = this.conduct_['reserved']['comment']
  },
  mounted() {
    this.editConductDialogVisibility = this.visible
  }
}
</script>

<style scoped>

</style>