<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-center">
      <h2>GPS Import</h2>
    </div>
    <div>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        <div class="d-flex flex-column align-center">
          <span>{{ snackbar.text }}</span>
          <span>{{ snackbar.error }}</span>
        </div>
      </v-snackbar>
    </div>
    <v-container fluid>
      <v-form>
        <v-layout class="d-flex">
          <v-file-input
              label="Select the file to import"
              outlined
              dense
              v-model="file"
          >
          </v-file-input>
        </v-layout>
        <v-layout class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="importFile">Import
            <v-icon right dark>mdi-cloud-upload</v-icon>
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/consts";

export default {
  name: "GPSImportComponent",
  data() {
    return {
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
      file: null
    }
  },
  methods: {
    importFile() {
      let user_logged = this.$store.getters.USER_LOGGED
      let formData = new FormData()
      formData.append("file", this.file)
      axios.post(API_BASE_URL + '/admin/import/gps', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-account": user_logged.name
        }
      })
          .then(() => {
            this.snackbar.color = "green"
            this.snackbar.timeout = 2500
            this.snackbar.text = "File successfully uploaded!"
            this.snackbar.error = ""
            this.snackbar.show = true
//            this.$store.dispatch('loadManholesFromDB')
            this.$store.commit('SET_MANHOLES_FROM_DB_LOADED', false)
            this.$store.commit('SET_ACTIVE_COMPONENT', 'manholes-component')
          })
          .catch(error => {
            this.snackbar.color = "red"
            this.snackbar.timeout = 3500
            this.snackbar.text = "Oups! An error occurs! Unable to import the file!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    },
    wait(ms) {
      let start = new Date().getTime();
      let end = start;
      while (end < start + ms) {
        end = new Date().getTime();
      }
    }
  }
}
</script>

<style scoped>

</style>