<template>
  <div>
    This page doesn't exist!
  </div>
</template>

<script>
export default {
  name: "not-found-component"
}
</script>

<style scoped>

</style>