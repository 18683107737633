import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import {API_BASE_URL} from "@/consts";

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        user_logged: {},
        is_admin: false,
        is_authenticated: false,
        active_component: 'map-component',
        codes_data_from_db: [],
        codes_data_from_db_loaded: false,
        highways_data_from_db: [],
        highways_data_from_db_loaded: false,
        owners_data_from_db: [],
        owners_data_from_db_loaded: false,
        purposes_data_from_db: [],
        purposes_data_from_db_loaded: false,
        categories_data_from_db: [],
        categories_data_from_db_loaded: false,
        obstacles_data_from_db: [],
        obstacles_data_from_db_loaded: false,
        item_types_data_from_db: [],
        item_types_data_from_db_loaded: false,
        items_data_from_db: [],
        items_data_from_db_loaded: false,
        manholes_data_from_db: [],
        manholes_data_from_db_loaded: false,
        users_data_from_db: [],
        users_data_from_db_loaded: false,
        accounts_data_from_db: [],
        accounts_data_from_db_loaded: false,
        manhole_edited: '',
        common_values: [],
        manholes_for_path: []
    },
    mutations: {
        SET_USER_LOGGED: (state, payload) => {
          state.user_logged = payload
        },
        SET_IS_ADMIN: (state, payload) => {
            state.is_admin = payload
        },
        SET_IS_AUTHENTICATED: (state, payload) => {
            state.is_authenticated = payload
        },
        SET_ACTIVE_COMPONENT: (state, payload) => {
            state.active_component = payload
        },
        SET_CODES_FROM_DB: (state, payload) => {
            state.codes_data_from_db = payload
        },
        SET_CODES_FROM_DB_LOADED: (state, payload) => {
            state.codes_data_from_db_loaded = payload
        },
        SET_HIGHWAYS_FROM_DB: (state, payload) => {
            state.highways_data_from_db = payload
        },
        SET_HIGHWAYS_FROM_DB_LOADED: (state, payload) => {
            state.highways_data_from_db_loaded = payload
        },
        SET_OWNERS_FROM_DB: (state, payload) => {
            state.owners_data_from_db = payload
        },
        SET_OWNERS_FROM_DB_LOADED: (state, payload) => {
            state.owners_data_from_db_loaded = payload
        },
        SET_OBSTACLES_FROM_DB: (state, payload) => {
            state.obstacles_data_from_db = payload
        },
        SET_OBSTACLES_FROM_DB_LOADED: (state, payload) => {
            state.obstacles_data_from_db_loaded = payload
        },
        SET_PURPOSES_FROM_DB: (state, payload) => {
            state.purposes_data_from_db = payload
        },
        SET_PURPOSES_FROM_DB_LOADED: (state, payload) => {
            state.purposes_data_from_db_loaded = payload
        },
        SET_CATEGORIES_FROM_DB: (state, payload) => {
            state.categories_data_from_db = payload
        },
        SET_CATEGORIES_FROM_DB_LOADED: (state, payload) => {
            state.categories_data_from_db_loaded = payload
        },
        SET_ITEM_TYPES_FROM_DB: (state, payload) => {
            state.item_types_data_from_db = payload
        },
        SET_ITEM_TYPES_FROM_DB_LOADED: (state, payload) => {
            state.item_types_data_from_db_loaded = payload
        },
        SET_ITEMS_FROM_DB: (state, payload) => {
            state.items_data_from_db = payload
        },
        SET_ITEMS_FROM_DB_LOADED: (state, payload) => {
            state.items_data_from_db_loaded = payload
        },
        SET_MANHOLES_FROM_DB: (state, payload) => {
            state.manholes_data_from_db = payload
        },
        SET_MANHOLES_FROM_DB_LOADED: (state, payload) => {
            state.manholes_data_from_db_loaded = payload
        },
        SET_USERS_FROM_DB: (state, payload) => {
            state.users_data_from_db = payload
        },
        SET_USERS_FROM_DB_LOADED: (state, payload) => {
            state.users_data_from_db_loaded = payload
        },
        SET_ACCOUNTS_FROM_DB: (state, payload) => {
            state.accounts_data_from_db = payload
        },
        SET_ACCOUNTS_FROM_DB_LOADED: (state, payload) => {
            state.accounts_data_from_db_loaded = payload
        },
        SET_MANHOLE_EDITED: (state, payload) => {
            state.manhole_edited = payload
        },
        SET_COMMON_VALUES: (state, payload) => {
            state.common_values = payload
        },
        SET_MANHOLES_FOR_PATH: (state, payload) => {
            state.manholes_for_path = payload
        }
    },
    getters: {
        USER_LOGGED: state => {
          return state.user_logged
        },
        IS_ADMIN: state => {
            return state.is_admin
        },
        IS_AUTHENTICATED: state => {
            return state.is_authenticated
        },
        ACTIVE_COMPONENT: state => {
            return state.active_component
        },
        CODES_FROM_DB: state => {
            return state.codes_data_from_db
        },
        CODES_FROM_DB_LOADED: state => {
            return state.codes_data_from_db_loaded
        },
        HIGHWAYS_FROM_DB: state => {
            return state.highways_data_from_db
        },
        HIGHWAYS_FROM_DB_LOADED: state => {
            return state.highways_data_from_db_loaded
        },
        OWNERS_FROM_DB: state => {
            return state.owners_data_from_db
        },
        OWNERS_FROM_DB_LOADED: state => {
            return state.owners_data_from_db_loaded
        },
        OBSTACLES_FROM_DB: state => {
            return state.obstacles_data_from_db
        },
        OBSTACLES_FROM_DB_LOADED: state => {
            return state.obstacles_data_from_db_loaded
        },
        PURPOSES_FROM_DB: state => {
            return state.purposes_data_from_db
        },
        PURPOSES_FROM_DB_LOADED: state => {
            return state.purposes_data_from_db_loaded
        },
        CATEGORIES_FROM_DB: state => {
            return state.categories_data_from_db
        },
        CATEGORIES_FROM_DB_LOADED: state => {
            return state.categories_data_from_db_loaded
        },
        ITEM_TYPES_FROM_DB: state => {
            return state.item_types_data_from_db
        },
        ITEM_TYPES_FROM_DB_LOADED: state => {
            return state.item_types_data_from_db_loaded
        },
        ITEMS_FROM_DB: state => {
            return state.items_data_from_db
        },
        ITEMS_FROM_DB_LOADED: state => {
            return state.items_data_from_db_loaded
        },
        MANHOLES_FROM_DB: state => {
            return state.manholes_data_from_db
        },
        MANHOLES_FROM_DB_LOADED: state => {
            return state.manholes_data_from_db_loaded
        },
        USERS_FROM_DB: state => {
            return state.users_data_from_db
        },
        USERS_FROM_DB_LOADED: state => {
            return state.users_data_from_db_loaded
        },
        ACCOUNTS_FROM_DB: state => {
            return state.accounts_data_from_db
        },
        ACCOUNTS_FROM_DB_LOADED: state => {
            return state.accounts_data_from_db_loaded
        },
        MANHOLE_EDITED: state => {
            return state.manhole_edited
        },
        COMMON_VALUES: state => {
            return state.common_values
        },
        MANHOLES_FOR_PATH: state => {
            return state.manholes_for_path
        }
    },
    actions: {
        loadCodesFromDB({ commit }) {
            axios.get(API_BASE_URL + '/codes')
                .then(response => {
                    commit('SET_CODES_FROM_DB', response.data)
                    commit('SET_CODES_FROM_DB_LOADED', true)
                    console.log('codes from DB loaded!')
                })
                .catch(error => {
                    commit('SET_CODES_FROM_DB_LOADED', false)
                    console.log("error -->" + error)
                })
        },
        loadHighwaysFromDB({commit}) {
            axios.get(API_BASE_URL + '/highways')
                .then(response => {
                    commit('SET_HIGHWAYS_FROM_DB', response.data)
                    commit('SET_HIGHWAYS_FROM_DB_LOADED', true)
                    console.log('highways from DB loaded!')
                })
                .catch(error => {
                    commit('SET_HIGHWAYS_FROM_DB_LOADED', false)
                    console.log("error -->" + error)
                })
        },
        loadOwnersFromDB({commit}) {
            axios.get(API_BASE_URL + '/owners')
                .then(response => {
                    commit('SET_OWNERS_FROM_DB', response.data)
                    commit('SET_OWNERS_FROM_DB_LOADED', true)
                    console.log('owners from DB loaded!')
                })
                .catch(error => {
                    commit('SET_OWNERS_FROM_DB_LOADED', false)
                    console.log("error -->" + error)
                })
        },
        loadPurposesFromDB({commit}) {
            axios.get(API_BASE_URL + '/purposes')
                .then(response => {
                    commit('SET_PURPOSES_FROM_DB', response.data)
                    commit('SET_PURPOSES_FROM_DB_LOADED', true)
                    console.log('purposes from DB loaded!')
                })
                .catch(error => {
                    commit('SET_PURPOSES_FROM_DB_LOADED', false)
                    console.log("error -->" + error)
                })
        },
        loadObstaclesFromDB({commit}) {
            axios.get(API_BASE_URL + '/obstacles')
                .then(response => {
                    commit('SET_OBSTACLES_FROM_DB', response.data)
                    commit('SET_OBSTACLES_FROM_DB_LOADED', true)
                    console.log('obstacles from DB loaded!')
                })
                .catch(error => {
                    commit('SET_OBSTACLES_FROM_DB_LOADED', false)
                    console.log("error -->" + error)
                })
        },
        // loadCategoriesFromDB({commit}) {
        //     axios.get(API_BASE_URL + '/categories')
        //         .then(response => {
        //             commit('SET_CATEGORIES_FROM_DB', response.data)
        //             commit('SET_CATEGORIES_FROM_DB_LOADED', true)
        //             console.log('categories from DB loaded!')
        //         })
        //         .catch(error => {
        //             commit('SET_CATEGORIES_FROM_DB_LOADED', false)
        //             console.log("error -->" + error)
        //         })
        // },
        loadItemTypesFromDB({commit}) {
            axios.get(API_BASE_URL + '/item-type')
                .then(response => {
                    commit('SET_ITEM_TYPES_FROM_DB', response.data)
                    commit('SET_ITEM_TYPES_FROM_DB_LOADED', true)
                    console.log('Item types from DB loaded!')
                })
                .catch(error => {
                    commit('SET_ITEM_TYPES_FROM_DB_LOADED', false)
                    console.log("error -->" + error)
                })
        },
        loadItemsFromDB({commit}) {
            axios.get(API_BASE_URL + '/items')
                .then(response => {
                    commit('SET_ITEMS_FROM_DB', response.data)
                    commit('SET_ITEMS_FROM_DB_LOADED', true)
                    console.log('items from DB loaded!')
                })
                .catch(error => {
                    commit('SET_ITEMS_FROM_DB_LOADED', false)
                    console.log("items -->" + error)
                })
        },
        loadManholesFromDB({commit}) {
            axios.get(API_BASE_URL + '/manholes')
                .then(response => {
                    commit('SET_MANHOLES_FROM_DB', response.data)
                    commit('SET_MANHOLES_FROM_DB_LOADED', true)
                    console.log('manholes from DB loaded!')
                })
                .catch(error => {
                    commit('SET_MANHOLES_FROM_DB_LOADED', false)
                    console.log("error -->" + error)
                })
        },
        loadUsersFromDB({commit}) {
            axios.get(API_BASE_URL + '/users')
                .then(response => {
                    commit('SET_USERS_FROM_DB', response.data)
                    commit('SET_USERS_FROM_DB_LOADED', true)
                    console.log('users from DB loaded!')
                })
                .catch(error => {
                    commit('SET_USERS_FROM_DB_LOADED', false)
                    console.log("error -->" + error)
                })
        },
        loadAccountsFromDB({commit}) {
            axios.get(API_BASE_URL + '/accounts')
                .then(response => {
                    commit('SET_ACCOUNTS_FROM_DB', response.data)
                    commit('SET_ACCOUNTS_FROM_DB_LOADED', true)
                    console.log('accounts from DB loaded!')
                })
                .catch(error => {
                    commit('SET_ACCOUNTS_FROM_DB_LOADED', false)
                    console.log("error -->" + error)
                })
        },
        loadCommonValues({commit}) {
            axios.get(API_BASE_URL + '/admin/common')
                .then(response => {
                    commit('SET_COMMON_VALUES', response.data)
                    console.log('common values loaded!')
                })
                .catch(error => {
                    console.error("error --> " + error)
                })
        },
        loadManholesFromDBForPath({ commit }) {
            axios.get(API_BASE_URL + '/tools/manholes')
                .then(response => {
                    // console.log('response: ' + JSON.stringify(response.data))
                    commit('SET_MANHOLES_FOR_PATH', response.data)
                })
                .catch()
        }
    },
    modules: {}
})
