<template>
  <div>
    <h1 style="text-align: center;">Data Infrastructure Management Tool</h1>
    <v-form>
      <v-card class="mx-auto my-20" max-width="374" elevation="2">
        <v-img height="120" :src="require('@/assets/jared-murray-NSuufgf-BME-unsplash.jpg')"></v-img>
        <v-card-title>Enter your credentials</v-card-title>
        <v-card-text>
          <v-text-field ref="txtEMail" label="E-mail" v-model="login_form.email">
          </v-text-field>
          <v-text-field label="Password" type="password" v-model="login_form.password">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="deep-purple lighten-2" text @click="reset">
            Reset
          </v-btn>
          <v-btn color="deep-purple lighten-2" text @click="login">
            Log in
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <div>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        <div class="d-flex flex-column align-center">
          <span>{{ snackbar.text }}</span>
          <span>{{ snackbar.error }}</span>
        </div>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from "axios";
import {API_BASE_URL} from "@/consts";

export default {
  name: 'Login',
  data() {
    return {
      login_form: {
        email: '',
        password: ''
      },
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
    }
  },
  methods: {
    login() {
      // console.log('log in')
      let user = {
        "email": this.login_form.email,
        "password": this.login_form.password
      }
      axios.post(API_BASE_URL + '/login', JSON.stringify(user), {
        headers: {"Content-Type": "application/json;charset=UTF-8"}
      })
      .then(response => {
        if (response.data['role']==='ADMIN') {
          this.$store.commit('SET_IS_ADMIN', true)
        }
        else {
          this.$store.commit('SET_IS_ADMIN', false)
        }
        this.error = false
        this.$store.commit('SET_IS_AUTHENTICATED', true)
        this.$store.commit('SET_USER_LOGGED', response.data)
        this.$router.push({name: 'Landingpage'})
      }).catch(error => {
        console.log('error --> ' + error)
        this.$store.commit('SET_IS_ADMIN', false)
        this.$store.commit('SET_IS_AUTHENTICATED', false)
        this.snackbar.color = "red"
        this.snackbar.timeout = 4500
        this.snackbar.text = "Oups! An error occurs! Please check your login and password!"
        this.snackbar.error = "(" + error + ")"
        this.snackbar.show = true
      })

    },
    reset() {
      // console.log('reset')
      this.login_form.email = ''
      this.login_form.password = ''
    }
  },
  mounted() {
    this.$refs.txtEMail.focus()
  }
}
</script>
