export function get_values(conduct) {
    return parseInt(conduct['position_x'].toString() + conduct['position_y'].toString())
}

export function sort_conducts(a,b) {
    a = get_values(a)
    b = get_values(b)
    if (a < b ) return -1
    if (a > b) return 1
    return 0
}