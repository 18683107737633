<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-center">
      <h2>Obstacle Management</h2>
    </div>
    <div>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        <div class="d-flex flex-column align-center">
          <span>{{ snackbar.text }}</span>
          <span>{{ snackbar.error }}</span>
        </div>
      </v-snackbar>
    </div>
    <v-container fluid>
      <div class="mr-5 ml-5 mt-2">
        <v-data-table v-model="selected" :headers="tableHeaders" :items="this.$store.getters.OBSTACLES_FROM_DB"
                      item-key="_id.$oid" :items-per-page="-1" caption="List of Obstacles" :single-select="singleSelect" show-select>
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="750px">
              <template v-slot:activator="{ on, attrs }">
                <v-layout justify-end>
                  <v-btn class="ma-2" color="primary" v-bind="attrs" v-on="on" :disabled="!isAdmin">
                    New Obstacle
                  </v-btn>
                </v-layout>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="5">
                        <v-text-field ref="txtObstacleType" v-model="editedObstacle.type" label="Obstacle type">
                          <template v-slot:label>
                            Obstacle type<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field v-model="editedObstacle.value" label="Obstacle value" type="number">
                          <template v-slot:label>
                            Obstacle value<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="650px">
              <v-card>
                <v-card-title class="text-h5">
                  <div class="d-flex flex-grow-1 justify-center">Are you sure you want to DELETE this obstacle?</div>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <v-layout justify-center class="red white--text">
                      <h3>!! This operation will also REMOVE this obstacle from MANHOLES !!</h3>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">CANCEL</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteObstacleConfirm">DELETE</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.creation_info="{ item }">
            <td>
              <div>{{ item.creation_info.account }}</div>
              <div>{{ item.creation_info.date }}</div>
            </td>
          </template>
          <template v-slot:item.update_info="{ item }">
            <td>
              <div>{{ item.update_info.account }}</div>
              <div>{{ item.update_info.date }}</div>
            </td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon :disabled="!isAdmin"
                small
                class="mr-2"
                @click="editObstacle(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon :disabled="!isAdmin"
                small
                @click="deleteObstacle(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:foot>
            <v-btn class="ma-1" @click="exportObstacles">EXPORT</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/consts";

export default {
  name: "ObstaclesComponent",
  data() {
    return {
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
      dialog: false,
      dialogDelete: false,
      selected: [],                       // for export
      singleSelect: false,                // for export
      obstacle_id: '',
      edition: false,
      editedObstacle: {
        type: '',
        value: 0,
        properties: {}
      },
      tableHeaders: [
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'type'
        },
        {
          text: 'Value',
          align: 'start',
          sortable: true,
          value: 'value'
        },
        {
          text: 'Created',
          align: 'start',
          sortable: false,
          value: 'creation_info'
        },
        {
          text: 'Updated',
          align: 'start',
          sortable: false,
          value: 'update_info'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    formTitle() {
      return this.edition === false ? 'New Obstacle' : 'Edit Obstacle'
    },
    isObstaclesLoadedFromDB() {
      return this.$store.getters.OBSTACLES_FROM_DB_LOADED
    },
    isAdmin() {
      return this.$store.getters.IS_ADMIN
    }
  },
  methods: {
    cancel() {
      // console.log('cancel')
      this.edition = false
      this.close()
    },
    save() {
      let user_logged = this.$store.getters.USER_LOGGED
      if (!this.edition) {
        // console.log('saving the new purpose')
        axios.post(API_BASE_URL + '/obstacles', this.editedObstacle, {
          headers: {"Content-Type": "application/json;charset=UTF-8",
            "x-account": user_logged.name}
        })
            .then(() => {
              // console.log(response.data)
              this.$store.dispatch('loadObstaclesFromDB')
              this.close()
              this.snackbar.color = "green"
              this.snackbar.timeout = 2500
              this.snackbar.text = "Obstacles successfully saved!"
              this.snackbar.error = ""
              this.snackbar.show = true
            })
            .catch(error => {
              this.close()
              this.snackbar.color = "red"
              this.snackbar.timeout = 3500
              this.snackbar.text = "Oups! An error occurs! Obstacle not saved!"
              if (error.response.data !== undefined) {
                this.snackbar.error = "( " + error.response.data['msg'] + " )"
              }
              this.snackbar.show = true
            })
      } else {
        // console.log('saving the edited purpose')
        // console.log('purpose_id --> ' + this.purpose_id)
        let obstacle = {
          "type": this.editedObstacle['type'],
          "value": parseInt(this.editedObstacle['value']),
          "properties": {}
        }
        // console.log('purpose --> ' + JSON.stringify(purpose))
        axios.put(API_BASE_URL + '/obstacles/' + this.obstacle_id, obstacle, {
          headers: {"Content-Type": "application/json;charset=UTF-8",
            "x-account": user_logged.name}
        })
            .then(() => {
              this.obstacle_id = ''
              // console.log(response.data)
              this.edition = false
              this.$store.dispatch('loadObstaclesFromDB')
              this.close()
              this.snackbar.color = "green"
              this.snackbar.timeout = 2500
              this.snackbar.text = "Obstacle successfully updated!"
              this.snackbar.error = ""
              this.snackbar.show = true
            })
            .catch(error => {
              this.edition = false
              this.obstacle_id = ''
              this.close()
              this.snackbar.color = "red"
              this.snackbar.timeout = 3500
              this.snackbar.text = "Oups! An error occurs! Obstacle not updated!"
              if (error.response.data !== undefined) {
                this.snackbar.error = "( " + error.response.data['msg'] + " )"
              }
              this.snackbar.show = true
            })
      }
    },
    close() {
      this.clearEditedObstacle()
      this.dialog = false
    },
    editObstacle(item) {
      this.edition = true
      // console.log(item)
      this.obstacle_id = item['_id']['$oid']
      this.editedObstacle = Object.assign({}, item)
      this.dialog = true
      this.$nextTick(()=>{
        const elt = this.$refs.txtObstacleType
        setTimeout(()=> {
          elt.focus()
        })
      })
    },
    deleteObstacle(item) {
      this.obstacle_id = item['_id']['$oid']
      this.dialogDelete = true
    },
    closeDelete() {
      this.obstacle_id = ''
      this.dialogDelete = false
    },
    deleteObstacleConfirm() {
      // console.log("purpose id selected --> " + this.purpose_id)
      axios.delete(API_BASE_URL + '/obstacles/' + this.obstacle_id)
          .then(() => {
            this.obstacle_id = ''
            // console.log('purpose successfully deleted!')
            // console.log(response.data)
            this.$store.dispatch('loadObstaclesFromDB')   // reset the list of obstacles
            this.$store.dispatch('loadManholesFromDB')    // reset the list of manholes
            this.closeDelete()
            this.snackbar.color = "green"
            this.snackbar.timeout = 2500
            this.snackbar.text = "Obstacle successfully deleted!"
            this.snackbar.error = ""
            this.snackbar.show = true
          })
          .catch(error => {
            this.obstacle_id = ''
            // console.log('error --> ' + error)
            this.closeDelete()
            this.snackbar.color = "red"
            this.snackbar.timeout = 2500
            this.snackbar.text = "Oups! An error occurs! Obstacle not deleted!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    },
    clearEditedObstacle() {
      this.editedObstacle = {
        type: '',
        value: 0,
        properties: {}
      }
    },
    exportObstacles() {
      axios.post(API_BASE_URL + '/admin/export', JSON.stringify(this.selected), {
        headers: {"Content-Type": "application/json;charset=UTF-8"}
      }).then((response) => {
        let f = new Blob([response.data], {type: 'text/csv'})
        let a = document.createElement('a')
        let current_date = new Date().toISOString()
        a.download = current_date + '_obstacles.csv'
        a.href = window.URL.createObjectURL(f)
        a.click()
      }).catch(error => {
        this.snackbar.color = "red"
        this.snackbar.timeout = 3500
        this.snackbar.text = "Oups! An error occurs! unable to export data!"
        if (error.response.data !== undefined) {
          this.snackbar.error = "( " + error.response.data['msg'] + " )"
        }
        this.snackbar.show = true
      })
    }
  },
  mounted() {
    if (!this.isObstaclesLoadedFromDB) {
      this.$store.dispatch('loadObstaclesFromDB')
    }
  }
}
</script>

<style scoped>

</style>