import Vue from 'vue'
import axios from 'axios'
import {LMap, LTileLayer, LMarker, LCircleMarker, LControlLayers, LWMSTileLayer, LLayerGroup, LTooltip, LPolyline} from 'vue2-leaflet';
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import 'leaflet/dist/leaflet.css';
import './assets/base.scss'
import VueAxios from "vue-axios";

Vue.config.productionTip = false
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-circle-marker', LCircleMarker)
Vue.component('l-control-layers', LControlLayers)
Vue.component('l-wms-tile-layer', LWMSTileLayer)
Vue.component('l-layer-group', LLayerGroup)
Vue.component('l-tooltip', LTooltip)
Vue.component('l-polyline', LPolyline)
Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify
}).$mount('#app')
