<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-center">
      <h2>Highway Management</h2>
    </div>
    <div>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        <div class="d-flex flex-column align-center">
          <span>{{ snackbar.text }}</span>
          <span>{{ snackbar.error }}</span>
        </div>
      </v-snackbar>
    </div>

    <v-container fluid>
      <div class="mr-5 ml-5 mt-2">
        <v-data-table v-model="selected" :headers="tableHeaders" :items="this.$store.getters.HIGHWAYS_FROM_DB"
                      item-key="_id.$oid" :items-per-page="-1" caption="List of highways" :single-select="singleSelect" show-select>
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="750px">
              <template v-slot:activator="{ on, attrs }">
                <v-layout justify-end>
                  <v-btn class="ma-2" color="primary" v-bind="attrs" v-on="on" :disabled="!isAdmin">
                    New Highway
                  </v-btn>
                </v-layout>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="5">
                        <v-text-field ref="txtHighwayName" v-model="editedHighway.name" label="Highway name">
                          <template v-slot:label>
                            Highway name<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field v-model="editedHighway.description" label="Highway description"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <h3>Directions</h3>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-combobox :items="codes_from_db" item-text="code" item-value="code" outlined
                                    label="Select the code" dense v-model="editedHighway.directions[0].code" ref="comboBox_0">
                          <template v-slot:label>
                            Select the code<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-combobox>
                      </v-col>
                      <v-col cols="6">
                        <v-combobox :items="codes_from_db" item-text="code" item-value="code" outlined
                                    label="Select the code" dense v-model="editedHighway.directions[1].code" ref="comboBox_1">
                          <template v-slot:label>
                            Select the code<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field dense v-model="editedHighway.directions[0].description"
                                      label="Direction description #1"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field dense v-model="editedHighway.directions[1].description"
                                      label="Direction description #2"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="650px">
              <v-card>
                <v-card-title class="text-h5">
                  <div class="d-flex flex-grow-1 justify-center">Are you sure you want to DELETE this highway?</div>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <v-layout justify-center class="red white--text">
                      <h3>!! This operation will also DELETE ALL MANHOLES on this highway !!</h3>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">CANCEL</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteHighwayConfirm">DELETE</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.directions="{ item }">
            <td>
              <div v-for="value in item.directions" :key="value.code">
                {{ value.code }} ( {{ value.description }} )
              </div>
            </td>
          </template>
          <template v-slot:item.creation_info="{ item }">
            <td>
              <div>{{ item.creation_info.account }}</div>
              <div>{{ item.creation_info.date }}</div>
            </td>
          </template>
          <template v-slot:item.update_info="{ item }">
            <td>
              <div>{{ item.update_info.account }}</div>
              <div>{{ item.update_info.date }}</div>
            </td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon :disabled="!isAdmin"
                small
                class="mr-2"
                @click="editHighway(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon :disabled="!isAdmin"
                small
                @click="deleteHighway(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:foot>
            <v-btn class="ma-1" @click="exportHighways">EXPORT</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/consts";

export default {
  name: "HighwaysComponent",
  data() {
    return {
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
      dialog: false,
      dialogDelete: false,
      selected: [],                       // for export
      singleSelect: false,                // for export
      highway_id: '',
      edition: false,
      editedHighway: {
        name: '',
        description: '',
        directions: [
          {
            code: '',
            description: ''
          },
          {
            code: '',
            description: ''
          }
        ]
      },
      defaultHighway: {
        name: '',
        description: '',
        directions: [
          {
            code: '',
            description: ''
          },
          {
            code: '',
            description: ''
          }
        ]
      },
      tableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description'
        },
        {
          text: 'Directions',
          align: 'start',
          sortable: false,
          value: 'directions'
        },
        {
          text: 'Created',
          align: 'start',
          sortable: false,
          value: 'creation_info'
        },
        {
          text: 'Updated',
          align: 'start',
          sortable: false,
          value: 'update_info'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ],
      codes_from_db: [],
      chosen_code_1: '',
      chosen_code_2: '',
      savedCodes: []
    }
  },
  computed: {
    formTitle() {
      return this.edition === false ? 'New Highway' : 'Edit Highway'
    },
    isHighwaysLoadedFromDB() {
      return this.$store.getters.HIGHWAYS_FROM_DB_LOADED
    },
    isAdmin() {
      return this.$store.getters.IS_ADMIN
    }
  },
  methods: {
    cancel() {
      console.log('cancel')
      this.edition = false
      this.highway_id = ''
      this.clearEditedHighway()
      this.close()
    },
    save() {
      this.$refs['comboBox_0'].blur()     // workaround to save the value in combobox even if the focus is not lost
      this.$refs['comboBox_1'].blur()
      this.$nextTick(() => {
        let user_logged = this.$store.getters.USER_LOGGED
        if (!this.edition) {
          // save the new highway
          axios.post(API_BASE_URL + '/highways', this.editedHighway, {
            headers: {"Content-Type": "application/json;charset=UTF-8",
              "x-account": user_logged.name}
          })
              .then(() => {
                this.close()
                this.loadCodesFromDB()
                this.$store.dispatch('loadHighwaysFromDB')
                this.snackbar.color = "green"
                this.snackbar.timeout = 2500
                this.snackbar.text = "Highway successfully saved!"
                this.snackbar.error = ""
                this.snackbar.show = true
              })
              .catch(error => {
                // console.log(error.response.data['msg'])
                // console.log("error --> " + JSON.stringify(error.response.data))
                this.close()
                this.snackbar.color = "red"
                this.snackbar.timeout = 3500
                this.snackbar.text = "Oups! An error occurs! Highway not saved!"
                if (error.response.data !== undefined) {
                  this.snackbar.error = "( " + error.response.data['msg'] + " )"
                }
                this.snackbar.show = true
              })
        } else {
          // update the highway
          axios.put(API_BASE_URL + '/highways/' + this.highway_id, this.editedHighway, {
            headers: {"Content-Type": "application/json;charset=UTF-8",
              "x-account": user_logged.name}
          })
              .then(() => {
                this.close()
                this.loadCodesFromDB()
                this.$store.dispatch('loadHighwaysFromDB')
                this.snackbar.color = "green"
                this.snackbar.timeout = 2500
                this.snackbar.text = "Highway successfully edited!"
                this.snackbar.error = ""
                this.snackbar.show = true
              })
              .catch(error => {
                this.close()
                this.snackbar.color = "red"
                this.snackbar.timeout = 3500
                this.snackbar.text = "Oups! An error occurs! Highway not updated!"
                if (error.response.data !== undefined) {
                  this.snackbar.error = "( " + error.response.data['msg'] + " )"
                }
                this.snackbar.show = true
              })
        }
      })
     },
    close() {
      this.clearEditedHighway()
      this.dialog = false
    },
    editHighway(item) {
      this.edition = true
      // console.log(item)
      this.highway_id = item['_id']['$oid']
      this.editedHighway = Object.assign({}, item)
      this.dialog = true
      this.$nextTick(() => {
        const elt = this.$refs.txtHighwayName
        setTimeout(()=>{
          elt.focus()
        })
      })
    },
    deleteHighway(item) {
      this.highway_id = item['_id']['$oid']
      this.dialogDelete = true
    },
    closeDelete() {
      this.highway_id = ''
      this.dialogDelete = false
    },
    deleteHighwayConfirm() {
      console.log("highway id selected --> " + this.highway_id)
      axios.delete(API_BASE_URL + '/highways/' + this.highway_id)
          .then(() => {
            this.highway_id = ''
            this.loadCodesFromDB()
            this.$store.dispatch('loadHighwaysFromDB')    // reset the list of highways
            this.$store.dispatch('loadManholesFromDB')    // reset the list of manholes
            this.closeDelete()
            this.snackbar.color = "green"
            this.snackbar.timeout = 2500
            this.snackbar.text = "Highway successfully deleted!"
            this.snackbar.error = ""
            this.snackbar.show = true
          })
          .catch(error => {
            this.highway_id = ''
            this.closeDelete()
            this.snackbar.color = "red"
            this.snackbar.timeout = 2500
            this.snackbar.text = "Oups! An error occurs! Highway not updated!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    },
    clearEditedHighway() {
      this.editedHighway = {
        name: '',
        description: '',
        directions: [
          {
            code: '',
            description: ''
          },
          {
            code: '',
            description: ''
          }
        ]
      }
    },
    loadCodesFromDB() {
      axios.get(API_BASE_URL + '/directions/codes')
          .then(response => {
            this.codes_from_db = response.data
            console.log('codes loaded from db!')
          })
          .catch(error => {
            console.log('Error loading codes from db!')
            console.log(error)
          })
    },
    exportHighways() {
      axios.post(API_BASE_URL + '/admin/export', JSON.stringify(this.selected), {
        headers: {"Content-Type": "application/json;charset=UTF-8"}
      }).then((response) => {
        let f = new Blob([response.data], {type: 'text/csv'})
        let a = document.createElement('a')
        let current_date = new Date().toISOString()
        a.download = current_date + '_highways.csv'
        a.href = window.URL.createObjectURL(f)
        a.click()
      }).catch(error => {
        this.snackbar.color = "red"
        this.snackbar.timeout = 3500
        this.snackbar.text = "Oups! An error occurs! unable to export data!"
        if (error.response.data !== undefined) {
          this.snackbar.error = "( " + error.response.data['msg'] + " )"
        }
        this.snackbar.show = true
      })
    }
  },
  mounted() {
    if (!this.isHighwaysLoadedFromDB) {
      this.$store.dispatch('loadHighwaysFromDB')
    }
    this.loadCodesFromDB()

  }
}
</script>

<style scoped>

</style>