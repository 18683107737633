var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_vm._m(0),_c('div',[_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"timeout":_vm.snackbar.timeout},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',[_vm._v(_vm._s(_vm.snackbar.text))]),_c('span',[_vm._v(_vm._s(_vm.snackbar.error))])])])],1),_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"mr-5 ml-5 mt-2"},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredItems,"item-key":"_id.$oid","items-per-page":-1,"caption":"List of items","single-select":_vm.singleSelect,"show-select":"","footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"header.owner",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{key:"indexOwners",attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-list',[_c('v-list-item',[(_vm.filters.hasOwnProperty('owners'))?_c('div',[_c('v-autocomplete',{attrs:{"multiple":"","dense":"","clearable":"","chips":"","small-chips":"","color":"light-blue lighten-3","items":_vm.columnValueListOwners(),"append-icon":"mdi-filter","label":_vm.filters['owners'] ? ("filter_by: " + (header.text)) : '',"hide-details":""},on:{"click:clear":function($event){return _vm.resetItemsFromDB('owners')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"caption",attrs:{"small":""}},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])])]}}],null,true),model:{value:(_vm.filters['owners']),callback:function ($$v) {_vm.$set(_vm.filters, 'owners', $$v)},expression:"filters['owners']"}})],1):_vm._e()])],1)],1)])]}},{key:"header.user",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{key:"indexUsers",attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-list',[_c('v-list-item',[(_vm.filters.hasOwnProperty('users'))?_c('div',[_c('v-autocomplete',{attrs:{"dense":"","multiple":"","clearable":"","chips":"","small-chips":"","color":"light-blue lighten-3","items":_vm.columnValueListUsers(),"append-icon":"mdi-filter","label":_vm.filters['users'] ? ("filter_by: " + (header.text)) : '',"hide-details":""},on:{"click:clear":function($event){return _vm.resetItemsFromDB('users')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"caption",attrs:{"small":""}},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])])]}}],null,true),model:{value:(_vm.filters['users']),callback:function ($$v) {_vm.$set(_vm.filters, 'users', $$v)},expression:"filters['users']"}})],1):_vm._e()])],1)],1)])]}},{key:"header.purpose",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{key:"indexPurposes",attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-list',[_c('v-list-item',[(_vm.filters.hasOwnProperty('purposes'))?_c('div',[_c('v-autocomplete',{attrs:{"dense":"","multiple":"","clearable":"","chips":"","small-chips":"","color":"light-blue lighten-3","items":_vm.columnValueListPurposes(),"append-icon":"mdi-filter","label":_vm.filters['purposes'] ? ("filter_by: " + (header.text)) : '',"hide-details":""},on:{"click:clear":function($event){return _vm.resetItemsFromDB('purposes')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"caption",attrs:{"small":""}},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])])]}}],null,true),model:{value:(_vm.filters['purposes']),callback:function ($$v) {_vm.$set(_vm.filters, 'purposes', $$v)},expression:"filters['purposes']"}})],1):_vm._e()])],1)],1)])]}},{key:"header.item_type",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{key:"indexItemTypes",attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-list',[_c('v-list-item',[(_vm.filters.hasOwnProperty('item_types'))?_c('div',[_c('v-autocomplete',{attrs:{"dense":"","multiple":"","clearable":"","chips":"","small-chips":"","color":"light-blue lighten-3","items":_vm.columnValueListItemTypes(),"append-icon":"mdi-filter","label":_vm.filters['item_types'] ? ("filter_by: " + (header.text)) : '',"hide-details":""},on:{"click:clear":function($event){return _vm.resetItemsFromDB('item_types')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"caption",attrs:{"small":""}},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])])]}}],null,true),model:{value:(_vm.filters['item_types']),callback:function ($$v) {_vm.$set(_vm.filters, 'item_types', $$v)},expression:"filters['item_types']"}})],1):_vm._e()])],1)],1)])]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"750px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","disabled":!_vm.isAdmin}},'v-btn',attrs,false),on),[_vm._v(" New Item ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"txtItemLabel",attrs:{"label":"Label","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Label"),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.editedItem.label),callback:function ($$v) {_vm.$set(_vm.editedItem, "label", $$v)},expression:"editedItem.label"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.owners,"label":"Owner","required":"","item-text":"name","return-object":"","dense":"","clearable":""},on:{"change":_vm.selectedOwner},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.editedItem.owner),callback:function ($$v) {_vm.$set(_vm.editedItem, "owner", $$v)},expression:"editedItem.owner"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.itemTypes,"label":"Type","required":"","item-text":"name","return-object":"","dense":""},on:{"change":_vm.selectedItemType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"label",fn:function(){return [_vm._v(" Type"),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.editedItem.item_type),callback:function ($$v) {_vm.$set(_vm.editedItem, "item_type", $$v)},expression:"editedItem.item_type"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.purposes,"label":"Purpose","required":"","item-text":"label","return-object":"","dense":"","clearable":""},on:{"change":_vm.selectedPurpose},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.label)+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.label))])]}}]),model:{value:(_vm.editedItem.purpose),callback:function ($$v) {_vm.$set(_vm.editedItem, "purpose", $$v)},expression:"editedItem.purpose"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"items":_vm.colors_from_db,"item-text":"color","item-value":"color","outlined":"","label":"Select the color","dense":""},model:{value:(_vm.editedItem.color),callback:function ($$v) {_vm.$set(_vm.editedItem, "color", $$v)},expression:"editedItem.color"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.users,"label":"User","required":"","item-text":"name","dense":"","return-object":"","clearable":""},on:{"change":_vm.selectedUser},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.editedItem.user),callback:function ($$v) {_vm.$set(_vm.editedItem, "user", $$v)},expression:"editedItem.user"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"650px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_c('div',{staticClass:"d-flex flex-grow-1 justify-center"},[_vm._v("Are you sure you want to DELETE this item?")])]),_c('v-card-text',[_c('v-container',{staticClass:"red white--text",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"flex flex-column"},[_c('h3',{staticClass:"text-center"},[_vm._v("!! This operation will also REMOVE this item from manholes !!")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("CANCEL")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteCableConfirm}},[_vm._v("DELETE")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":!_vm.isAdmin,"small":""},on:{"click":function($event){return _vm.editCable(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"disabled":!_vm.isAdmin,"small":""},on:{"click":function($event){return _vm.deleteCable(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOwnerName(item))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserName(item))+" ")]}},{key:"item.item_type",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_vm._v("name: "+_vm._s(item.item_type.name))]),_c('div',[_vm._v("category: "+_vm._s(item.item_type.category))]),_c('div',[_vm._v("duct: "+_vm._s(_vm.itemTypeIsDuct(item)))])])]}},{key:"item.purpose",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPurposeLabel(item))+" ")]}},{key:"item.creation_info",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_vm._v(_vm._s(item.creation_info.account))]),_c('div',[_vm._v(_vm._s(item.creation_info.date))])])]}},{key:"item.update_info",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_vm._v(_vm._s(item.update_info.account))]),_c('div',[_vm._v(_vm._s(item.update_info.date))])])]}},{key:"foot",fn:function(){return [_c('v-btn',{staticClass:"ma-1",on:{"click":_vm.exportCables}},[_vm._v("EXPORT")])]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row justify-center"},[_c('h2',[_vm._v("Item Management")])])}]

export { render, staticRenderFns }