<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-center">
      <h2>Item Management</h2>
    </div>
    <div>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        <div class="d-flex flex-column align-center">
          <span>{{ snackbar.text }}</span>
          <span>{{ snackbar.error }}</span>
        </div>
      </v-snackbar>
    </div>
    <v-container fluid>
      <div class="mr-5 ml-5 mt-2">
        <v-data-table v-model="selected" :headers="tableHeaders" :items="filteredItems"
                      item-key="_id.$oid" :items-per-page="-1" caption="List of items" :single-select="singleSelect" show-select
                      :footer-props="footerProps">
          <template v-slot:header.owner="{ header }">
            {{ header.text }}

            <v-menu key="indexOwners" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small color="red">
                    mdi-filter-variant
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-list>
                  <v-list-item>
                    <div v-if="filters.hasOwnProperty('owners')">
                      <v-autocomplete multiple dense clearable chips small-chips color="light-blue lighten-3" :items="columnValueListOwners()" append-icon="mdi-filter"
                                      v-model="filters['owners']" :label="filters['owners'] ? `filter_by: ${header.text}` : ''
                    " hide-details @click:clear="resetItemsFromDB('owners')">
                        <template v-slot:selection="{ item }">
                          <v-chip small class="caption">
                            <span>
                              {{ item }}
                            </span>
                          </v-chip>
                          <!--                            <span v-if="index === 5" class="grey--text caption">-->
                          <!--                            (+{{ filters[header.value].length - 5 }} others)-->
                          <!--                          </span>-->
                        </template>
                      </v-autocomplete>
                    </div>
                  </v-list-item>
                </v-list>
              </div>
            </v-menu>
          </template>

          <template v-slot:header.user="{ header }">
            {{ header.text }}

            <v-menu key="indexUsers" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small color="red">
                    mdi-filter-variant
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-list>
                  <v-list-item>
                    <div v-if="filters.hasOwnProperty('users')">
                      <v-autocomplete dense multiple clearable chips small-chips color="light-blue lighten-3" :items="columnValueListUsers()" append-icon="mdi-filter"
                                      v-model="filters['users']" :label="filters['users'] ? `filter_by: ${header.text}` : ''
                    " hide-details @click:clear="resetItemsFromDB('users')">
                        <template v-slot:selection="{ item }">
                          <v-chip small class="caption">
                            <span>
                              {{ item }}
                            </span>
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </div>
                  </v-list-item>
                </v-list>
              </div>
            </v-menu>
          </template>

          <template v-slot:header.purpose="{ header }">
            {{ header.text }}

            <v-menu key="indexPurposes" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small color="red">
                    mdi-filter-variant
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-list>
                  <v-list-item>
                    <div v-if="filters.hasOwnProperty('purposes')">
                      <v-autocomplete dense multiple clearable chips small-chips color="light-blue lighten-3" :items="columnValueListPurposes()" append-icon="mdi-filter"
                                      v-model="filters['purposes']" :label="filters['purposes'] ? `filter_by: ${header.text}` : ''
                    " hide-details @click:clear="resetItemsFromDB('purposes')">
                        <template v-slot:selection="{ item }">
                          <v-chip small class="caption">
                            <span>
                              {{ item }}
                            </span>
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </div>
                  </v-list-item>
                </v-list>
              </div>
            </v-menu>
          </template>

          <template v-slot:header.item_type="{ header }">
            {{ header.text }}

            <v-menu key="indexItemTypes" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small color="red">
                    mdi-filter-variant
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-list>
                  <v-list-item>
                    <div v-if="filters.hasOwnProperty('item_types')">
                      <v-autocomplete dense multiple clearable chips small-chips color="light-blue lighten-3" :items="columnValueListItemTypes()" append-icon="mdi-filter"
                                      v-model="filters['item_types']" :label="filters['item_types'] ? `filter_by: ${header.text}` : ''
                    " hide-details @click:clear="resetItemsFromDB('item_types')">
                        <template v-slot:selection="{ item }">
                          <v-chip small class="caption">
                            <span>
                              {{ item }}
                            </span>
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </div>
                  </v-list-item>
                </v-list>
              </div>
            </v-menu>
          </template>

          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="750px">
              <template v-slot:activator="{ on, attrs }">
                <v-layout justify-end>
                  <v-btn class="ma-2" color="primary" v-bind="attrs" v-on="on" :disabled="!isAdmin">
                    New Item
                  </v-btn>
                </v-layout>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field ref="txtItemLabel" label="Label" v-model="editedItem.label" dense>
                          <template v-slot:label>
                            Label<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-select :items="owners" label="Owner" v-model="editedItem.owner" required
                                  @change="selectedOwner" item-text="name" return-object dense clearable>
                          <template v-slot:item="{ item, attrs, on}">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-title>
                                {{ item.name }}
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template v-slot:selection="{ item }">
                            <span>{{ item.name }}</span>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select :items="itemTypes" label="Type" v-model="editedItem.item_type"
                                  required @change="selectedItemType" item-text="name" return-object dense>
                          <template v-slot:item="{ item, attrs, on}">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-title>
                                {{ item.name }}
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template v-slot:selection="{ item }">
                            <span>{{ item.name }}</span>
                          </template>
                          <template v-slot:label>
                            Type<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-select :items="purposes" label="Purpose" required v-model="editedItem.purpose"
                                  @change="selectedPurpose" item-text="label" return-object dense clearable>
                          <template v-slot:item="{ item, attrs, on}">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-title>
                                {{ item.label }}
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template v-slot:selection="{ item }">
                            <span>{{ item.label }}</span>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-combobox :items="colors_from_db" item-text="color" item-value="color" outlined
                                    label="Select the color" dense v-model="editedItem.color">
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select :items="users" label="User" required v-model="editedItem.user"
                                  @change="selectedUser" item-text="name" dense return-object clearable>
                          <template v-slot:item="{ item, attrs, on}">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-title>
                                {{ item.name }}
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template v-slot:selection="{ item }">
                            <span>{{ item.name }}</span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="650px">
              <v-card>
                <v-card-title class="text-h5">
                  <div class="d-flex flex-grow-1 justify-center">Are you sure you want to DELETE this item?</div>
                </v-card-title>
                <v-card-text>
                  <v-container fluid class="red white--text">
                    <v-layout class="flex flex-column">
                      <h3 class="text-center">!! This operation will also REMOVE this item from manholes !!</h3>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">CANCEL</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteCableConfirm">DELETE</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon :disabled="!isAdmin"
                small
                class="mr-2"
                @click="editCable(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon :disabled="!isAdmin"
                small
                @click="deleteCable(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.owner="{ item }">
            {{ getOwnerName(item) }}
          </template>
          <template v-slot:item.user="{ item }">
            {{ getUserName(item) }}
          </template>
          <template v-slot:item.item_type="{ item }">
            <td>
              <div>name: {{ item.item_type.name }}</div>
              <div>category: {{ item.item_type.category }}</div>
              <div>duct: {{ itemTypeIsDuct(item) }}</div>
            </td>
          </template>
          <template v-slot:item.purpose="{ item }">
            {{ getPurposeLabel(item) }}
          </template>
          <template v-slot:item.creation_info="{ item }">
            <td>
              <div>{{ item.creation_info.account }}</div>
              <div>{{ item.creation_info.date }}</div>
            </td>
          </template>
          <template v-slot:item.update_info="{ item }">
            <td>
              <div>{{ item.update_info.account }}</div>
              <div>{{ item.update_info.date }}</div>
            </td>
          </template>
          <template v-slot:foot>
            <v-btn class="ma-1" @click="exportCables">EXPORT</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/consts";

export default {
  name: "ItemsComponent",
  data() {
    return {
      filters: { owners: [], users : [], item_types: [], purposes: []},
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
      dialog: false,
      dialogDelete: false,
      selected: [],
      singleSelect: false,
      selected_owner_id: '',
      selected_user_id: '',
      selected_purpose_id: '',
      selected_item_type_id: '',
      item_id: '',
      edition: false,
      editedItem: {
        label: '',
        owner: '',
        item_type: '',
        purpose: '',
        color: '',
        user: ''
      },
      owners: [],
      users: [],
      purposes: [],
      colors_from_db: [],
      itemTypes: [],
      tableHeaders: [
        {
          text: 'Label',
          align: 'start',
          sortable: true,
          value: 'label'
        },
        {
          text: 'Owner',
          align: 'start',
          sortable: true,
          value: 'owner'
        },
        {
          text: 'User',
          align: 'start',
          sortable: true,
          value: 'user'
        },
        {
          text: 'Item Type',
          align: 'start',
          sortable: true,
          value: 'item_type'
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: true,
          value: 'purpose'
        },
        {
          text: 'Color',
          align: 'start',
          sortable: true,
          value: 'color'
        },
        {
          text: 'Created',
          align: 'start',
          sortable: true,
          value: 'creation_info'
        },
        {
          text: 'Updated',
          align: 'start',
          sortable: true,
          value: 'update_info'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ],
      footerProps: {'items-per-page-options': [20, 50, 100, 500, 1000, -1]},
      itemsFromDB: [],
      userFilterReset: false
    }
  },
  computed: {
    formTitle() {
      return this.edition === false ? 'New Item' : 'Edit Item'
    },
    // isItemsLoadedFromDB() {
    //   return this.$store.getters.ITEMS_FROM_DB_LOADED
    // },
    isOwnersLoadedFromDB() {
      return this.$store.getters.OWNERS_FROM_DB_LOADED
    },
    isUsersLoadedFromDB() {
      return this.$store.getters.USERS_FROM_DB_LOADED
    },
    isAdmin() {
      return this.$store.getters.IS_ADMIN
    },
    isUsersFilterEmpty() {
      return this.filters['users'].length===0
    },
    isPurposesFilterEmpty() {
      return this.filters['purposes'].length===0
    },
    isItemTypesFilterEmpty() {
      return this.filters['item_types'].length===0
    },
    filteredItems() {

      if (this.filters['owners'].length>0) {
        // console.log('filters with owners')
        this.getItemsFilteredByOwners(this.itemsFromDB)
      }
      if (!this.isUsersFilterEmpty) {
        // console.log('filters with users')
        // console.log('before: ' + JSON.stringify(this.itemsFromDB))
        this.getItemsFilteredByUsers(this.itemsFromDB)
        // console.log('after: ' + JSON.stringify(this.itemsFromDB))
      }
      if (!this.isPurposesFilterEmpty) {
        // console.log('filters with users')
        // console.log('before: ' + JSON.stringify(this.itemsFromDB))
        this.getItemsFilteredByPurposes(this.itemsFromDB)
        // console.log('after: ' + JSON.stringify(this.itemsFromDB))
      }
      if (!this.isItemTypesFilterEmpty) {
        // console.log('filters with users')
        // console.log('before: ' + JSON.stringify(this.itemsFromDB))
        this.getItemsFilteredByItemTypes(this.itemsFromDB)
        // console.log('after: ' + JSON.stringify(this.itemsFromDB))
      }

      return this.itemsFromDB
    }
  },
  methods: {
    resetItemsFromDB(value) {
      if (value==='users') this.filters['users'] = []
      if (value==='owners') this.filters['owners'] = []
      if (value==='purposes') this.filters['purposes'] = []
      if (value==='item_type') this.filters['item_types'] = []
      // console.log(JSON.stringify(this.filters))
      axios.get(API_BASE_URL + '/items')
          .then(response => {
            this.$store.commit('SET_ITEMS_FROM_DB', response.data)
            this.$store.commit('SET_ITEMS_FROM_DB_LOADED', true)
            this.itemsFromDB = response.data
            console.log('items from DB loaded!')
          })
          .catch(error => {
            this.$store.commit('SET_ITEMS_FROM_DB_LOADED', false)
            this.itemsFromDB = []
            this.snackbar.color = "red"
            this.snackbar.timeout = 3500
            this.snackbar.text = "Oups! An error occurs! Items not loaded from the datasatore!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    },
    getItemsFilteredByOwners(items) {
      this.itemsFromDB = items.filter((item) => {
        for (let i = 0; i < this.filters['owners'].length; i++) {
          if (item['owner']['name']===this.filters['owners'][i]) return true
        }
      })
    },
    getItemsFilteredByUsers(items) {
      this.itemsFromDB = items.filter((item) => {
            for (let i = 0; i < this.filters['users'].length; i++) {
              if (item['user']['name'] === this.filters['users'][i]) {
                return true
              }
            }
      })
    },
    getItemsFilteredByPurposes(items) {
      this.itemsFromDB = items.filter((item) => {
        for (let i = 0; i < this.filters['purposes'].length; i++) {
          if (item['purpose']['label'] === this.filters['purposes'][i]) {
            return true
          }
        }
      })
    },
    getItemsFilteredByItemTypes(items) {
      this.itemsFromDB = items.filter((item) => {
        for (let i = 0; i < this.filters['item_types'].length; i++) {
          if (item['item_type']['name'] === this.filters['item_types'][i]) {
            return true
          }
        }
      })
    },
    columnValueListPurposes() {
      let x = JSON.parse(JSON.stringify(this.$store.getters.ITEMS_FROM_DB))
      // console.log('x: ' + JSON.stringify(x))
      return x.map((d) => {
        if (d['purpose'] !==null && d['purpose'] !== undefined && d['purpose'] !== '') {
          // console.log(d['purpose']['label'])
          return d['purpose']['label']
        }
        if (d['purpose']==='') {
          return ''
        }
      }).filter( d => d !== '')
    },
    columnValueListOwners() {
      let x = JSON.parse(JSON.stringify(this.$store.getters.ITEMS_FROM_DB))
      // console.log('x: ' + JSON.stringify(x))
      return x.map((d) => {
            if (d['owner']!=='') {
              // console.log(d['owner']['name'])
              return d['owner']['name']
            }
            else {
              // console.log('no name!')
              return ''
            }
          }).filter( d => d !== '')
    },
    columnValueListUsers() {
      let x = JSON.parse(JSON.stringify(this.$store.getters.ITEMS_FROM_DB))
      // console.log('x: ' + JSON.stringify(x))
      return x.map((d) => {
        if (d['user']!=='') {
          // console.log(d['owner']['name'])
          return d['user']['name']
        }
        else {
          // console.log('no name!')
          return ''
        }
      }).filter( d => d !== '')
    },
    columnValueListItemTypes() {
      let x = JSON.parse(JSON.stringify(this.$store.getters.ITEMS_FROM_DB))
      // console.log('x: ' + JSON.stringify(x))
      return x.map((d) => {
        if (d['item_type']!=='') {
          // console.log(d['owner']['name'])
          return d['item_type']['name']
        }
      })
    },
    cancel() {
      // console.log('cancel')
      this.edition = false
      this.close()
    },
    save()   {
      let user_logged = this.$store.getters.USER_LOGGED
      if (!this.edition) {
        // console.log('saving the new item')
        let purpose_id = ''
        let owner_id = ''
        let user_id = ''
        let item_type_id = ''
        if (this.selected_purpose_id !== '') purpose_id = this.selected_purpose_id
        if (this.selected_owner_id !== '') owner_id = this.selected_owner_id
        if (this.selected_user_id !== '') user_id = this.selected_user_id
        if (this.selected_item_type_id !== '') item_type_id = this.selected_item_type_id
        let new_item = {
          "label": this.editedItem.label,
          "color": this.editedItem.color,
          "purpose_id": purpose_id,
          "owner_id": owner_id,
          "user_id": user_id,
          "item_type_id": item_type_id
        }
        // console.log("new cable --> " + JSON.stringify(new_cable))
        axios.post(API_BASE_URL + '/items', new_item, {
          headers: {"Content-Type": "application/json;charset=UTF-8",
            "x-account": user_logged.name}
        })
            .then(() => {
              // console.log(response.data)
              // this.$store.commit('SET_ITEMS_FROM_DB', response.data)
              // this.$store.commit('SET_ITEMS_FROM_DB_LOADED', true)
              // this.itemsFromDB = response.data
 //             this.$store.dispatch('loadItemsFromDB')
              this.loadItemsFromDB()
              this.close()
              this.snackbar.color = "green"
              this.snackbar.timeout = 2500
              this.snackbar.text = "Item successfully saved!"
              this.snackbar.error = ""
              this.snackbar.show = true
            })
            .catch(error => {
              // console.log('error -> ' + error)
              this.close()
              this.snackbar.color = "red"
              this.snackbar.timeout = 3500
              this.snackbar.text = "Oups! An error occurs! Item not saved!"
              if (error.response.data !== undefined) {
                this.snackbar.error = "( " + error.response.data['msg'] + " )"
              }
              this.snackbar.show = true
            })
      }
      else {
        // console.log('saving the edited item')
        // console.log('cable_id --> ' + this.cable_id)
        // console.log("editedCable --> " + JSON.stringify(this.editedCable))
        let purpose_id = ''
        let owner_id = ''
        let user_id = ''
        let item_type_id = ''
        if (this.editedItem['purpose'] !== null && this.editedItem['purpose']['_id'] !== undefined) purpose_id = this.editedItem['purpose']['_id']['$oid']
        if (this.editedItem['owner'] !== null && this.editedItem['owner']['_id'] !== undefined) owner_id = this.editedItem['owner']['_id']['$oid']
        if (this.editedItem['user'] !== null && this.editedItem['user']['_id'] !== undefined) user_id = this.editedItem['user']['_id']['$oid']
        if (this.editedItem['item_type'] !== null && this.editedItem['item_type']['_id'] !== undefined) item_type_id = this.editedItem['item_type']['_id']['$oid']
        let item = {
          "label": this.editedItem['label'],
          "color": this.editedItem['color'],
          "purpose_id": purpose_id,
          "owner_id": owner_id,
          "user_id": user_id,
          "item_type_id": item_type_id
        }
        // console.log('cable to be updated --> ' + JSON.stringify(cable))
        axios.put(API_BASE_URL + '/items/' + this.item_id, item, {
          headers: {"Content-Type": "application/json;charset=UTF-8",
            "x-account": user_logged.name}
        })
            .then(() => {
              this.item_id = ''
              // console.log(response.data)
              this.edition = false
              // this.$store.commit('SET_ITEMS_FROM_DB', response.data)
              // this.$store.commit('SET_ITEMS_FROM_DB_LOADED', true)
              // this.itemsFromDB = response.data
              // this.$store.dispatch('loadItemsFromDB')
              this.loadItemsFromDB()
              this.close()
              this.snackbar.color = "green"
              this.snackbar.timeout = 2500
              this.snackbar.text = "Item successfully updated!"
              this.snackbar.error = ""
              this.snackbar.show = true
            })
            .catch(error => {
              this.edition = false
              this.item_id = ''
              // console.log('error -> ' + error)
              this.close()
              this.snackbar.color = "red"
              this.snackbar.timeout = 3500
              this.snackbar.text = "Oups! An error occurs! Item not updated!"
              if (error.response.data !== undefined) {
                this.snackbar.error = "( " + error.response.data['msg'] + " )"
              }
              this.snackbar.show = true
            })
      }
    },
    close() {
      this.clearEditedItem()
      this.clearEditedItemIds()
      this.dialog = false
    },
    editCable(item) {
      this.edition = true
      // console.log("cable to be edited --> " + JSON.stringify(item))
      this.item_id = item['_id']['$oid']
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.$nextTick(()=>{
        const elt = this.$refs.txtItemLabel
        setTimeout(()=>{
          elt.focus()
        })
      })
    },
    deleteCable(item) {
      this.item_id = item['_id']['$oid']
      this.dialogDelete = true
    },
    closeDelete() {
      this.item_id = ''
      this.dialogDelete = false
    },
    deleteCableConfirm() {
      // console.log("cable id selected --> " + this.cable_id)
      axios.delete(API_BASE_URL + '/items/' + this.item_id)
          .then(() => {
            this.item_id = ''
            // console.log('cable successfully deleted!')
            // console.log(response.data)
            // this.$store.dispatch('loadItemsFromDB')       // reset the list of items
            // this.$store.dispatch('loadManholesFromDB')    // reset the list of manholes
            this.$store.commit('SET_MANHOLES_FROM_DB_LOADED', false)
            this.loadItemsFromDB()
            this.closeDelete()
            this.snackbar.color = "green"
            this.snackbar.timeout = 2500
            this.snackbar.text = "Item successfully deleted!"
            this.snackbar.error = ""
            this.snackbar.show = true
          })
          .catch(error => {
            this.item_id = ''
            // console.log('error --> ' + error)
            this.closeDelete()
            this.snackbar.color = "red"
            this.snackbar.timeout = 3500
            this.snackbar.text = "Oups! An error occurs! Item not deleted!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    },
    clearEditedItem() {
      this.editedItem = {
        label: '',
        owner: '',
        user: '',
        item_type: '',
        purpose: '',
        color: ''
      }
    },
    clearEditedItemIds() {
      this.selected_item_type_id = ''
      this.selected_owner_id = ''
      this.selected_purpose_id = ''
      this.selected_user_id = ''
    },
    selectedPurpose(purpose) {
      // console.log('purpose -> ' + JSON.stringify(purpose))
      if (purpose !== null && purpose !== undefined) this.selected_purpose_id = purpose['_id']['$oid']
      else this.selected_purpose_id = ''
      console.log('purpose id -> ' + this.selected_purpose_id)
    },
    selectedOwner(owner) {
      // console.log('selected owner -> ' + JSON.stringify(owner))
      if (owner !== null && owner !== undefined) this.selected_owner_id = owner['_id']['$oid']
      else this.selected_owner_id = ''
      console.log('owner id -> ' + this.selected_owner_id)
    },
    selectedUser(user) {
      // console.log('selected user -> ' + JSON.stringify(user))
      if (user !== null && user !== undefined) this.selected_user_id = user['_id']['$oid']
      else this.selected_user_id = ''
      console.log('user id -> ' + this.selected_user_id)
    },
    selectedItemType(itemType) {
      // console.log('itemType -> ' + JSON.stringify(itemType))
      if (itemType !== null && itemType !== undefined) this.selected_item_type_id = itemType['_id']['$oid']
      else this.selected_item_type_id = ''
      console.log('item type id -> ' + this.selected_item_type_id)
    },
    loadColorsFromDB() {
      axios.get(API_BASE_URL + '/item-type/colors')
          .then(response => {
            this.colors_from_db = response.data
            console.log('colors loaded from DB')
          })
          .catch(error => {
            console.log(error)
          })
    },
    exportCables() {
      // console.log("export --> " + JSON.stringify(this.selected))
      axios.post(API_BASE_URL + '/admin/export', JSON.stringify(this.selected), {
        headers: {"Content-Type": "application/json;charset=UTF-8"}
      }).then((response) => {
        // console.log('response headers --> ' + JSON.stringify(response.headers))
        // console.log('response data --> ' + response.data)
        let f = new Blob([response.data],  {type: 'text/csv'})
        let a = document.createElement('a')
        let current_date = new Date().toISOString()
        a.download = current_date + '_export_cables.csv'
        a.href = window.URL.createObjectURL(f)
        a.click()
      }).catch(error => {
        console.log('error --> ' + error)
      })
    },
    getPurposeLabel(item) {
      // console.log('item --> ' + JSON.stringify(item))
      if (item.purpose === null || item.purpose === '') return ""
      else return item.purpose.label
    },
    getUserName(item) {
      if (item.user === null || item.user === '') return ""
      else return item.user.name
    },
    getOwnerName(item) {
      if (item.owner === null || item.owner === '') return ""
      else return item.owner.name
    },
    itemTypeIsDuct(item) {
      if (item.item_type.is_duct) return 'YES'
      else return 'NO'
    },
    loadItemsFromDB() {
      axios.get(API_BASE_URL + '/items')
          .then(response => {
            this.$store.commit('SET_ITEMS_FROM_DB', response.data)
            this.$store.commit('SET_ITEMS_FROM_DB_LOADED', true)
            this.itemsFromDB = response.data
            this.snackbar.color = "green"
            this.snackbar.timeout = 2500
            this.snackbar.text = "Items successfully loaded from datastore!"
            this.snackbar.error = ""
            this.snackbar.show = true
            console.log('items from DB loaded!')
          })
          .catch(error => {
            this.$store.commit('SET_ITEMS_FROM_DB_LOADED', false)
            this.itemsFromDB = []
            this.snackbar.color = "red"
            this.snackbar.timeout = 3500
            this.snackbar.text = "Oups! An error occurs! Items not loaded from the datasatore!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    }
  },
  beforeMount() {
    // if (!this.isItemsLoadedFromDB) {
      this.loadItemsFromDB()
//      this.$store.dispatch('loadItemsFromDB')
//     }
  },
  mounted() {
//     if (!this.isItemsLoadedFromDB) {
//       this.loadItemsFromDB()
// //      this.$store.dispatch('loadItemsFromDB')
//     }
    this.loadColorsFromDB()
    axios.get(API_BASE_URL + '/owners')
        .then(response => {
          this.owners = response.data
          console.log('owners from DB loaded!')
        })
        .catch(error => {
          console.log("error -->" + error)
        })
    axios.get(API_BASE_URL + '/users')
        .then(response => {
          this.users = response.data
          console.log('users from DB loaded!')
        })
        .catch(error => {
          console.log("error -->" + error)
        })
    axios.get(API_BASE_URL + '/item-type')
        .then(response => {
          this.itemTypes = response.data
          console.log('item types from DB loaded!')
        })
        .catch(error => {
          console.log("error -->" + error)
        })
    axios.get(API_BASE_URL + '/purposes')
        .then(response => {
          this.purposes = response.data
          console.log('purposes from DB loaded!')
        })
        .catch(error => {
          console.log("error -->" + error)
        })
  }
}
</script>

<style scoped>

</style>