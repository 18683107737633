import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Landingpage from "@/views/Landingpage";
import NotFoundComponent from "@/components/NotFoundComponent"
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Login,
        beforeEnter: (to, from, next) => {
             next({path: '/login'})
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/landingpage',
        name: 'Landingpage',
        component: Landingpage,
        beforeEnter: (to, from, next) => {
            if (!store.getters.IS_AUTHENTICATED) {
                // console.log("(Not OK )store --> " + store.getters.IS_AUTHENTICATED)
                next({name: "Login"})
            }
            else {
                // console.log("(OK) store --> " +  store.getters.IS_AUTHENTICATED)
                next()
            }
        }
    },
    {
        path: '/*',
        name: 'not-found-component',
        component: NotFoundComponent
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router
