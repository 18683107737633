<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-center">
      <h2>Owner Management</h2>
    </div>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
      <div class="d-flex flex-column align-center">
        <span>{{ snackbar.text }}</span>
        <span>{{ snackbar.error }}</span>
      </div>
    </v-snackbar>
    <v-container fluid>
      <div class="mr-5 ml-5 mt-2">
        <v-data-table v-model="selected" :headers="tableHeaders" :items="this.$store.getters.OWNERS_FROM_DB"
                      item-key="_id.$oid" :items-per-page="-1" caption="List of Owners" :single-select="singleSelect" show-select>
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="750px">
              <template v-slot:activator="{ on, attrs }">
                <v-layout justify-end>
                  <v-btn class="ma-2" color="primary" v-bind="attrs" v-on="on" :disabled="!isAdmin">
                    New Owner
                  </v-btn>
                </v-layout>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="5">
                        <v-text-field ref="txtOwnerName" v-model="editedOwner.name" label="Owner name">
                          <template v-slot:label>
                            Owner name<span class="red--text"><strong>*</strong></span>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field v-model="editedOwner.description" label="Owner description"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="650px">
              <v-card>
                <v-card-title class="text-h5">
                  <div class="d-flex flex-grow-1 justify-center">Are you sure you want to DELETE this owner?</div>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <v-layout justify-center class="red white--text">
                      <h3>!! This operation will also REMOVE this owner from ITEMS !!</h3>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">CANCEL</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteOwnerConfirm">DELETE</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.creation_info="{ item }">
            <td>
              <div>{{ item.creation_info.account }}</div>
              <div>{{ item.creation_info.date }}</div>
            </td>
          </template>
          <template v-slot:item.update_info="{ item }">
            <td>
              <div>{{ item.update_info.account }}</div>
              <div>{{ item.update_info.date }}</div>
            </td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon :disabled="!isAdmin"
                small
                class="mr-2"
                @click="editOwner(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon :disabled="!isAdmin"
                small
                @click="deleteOwner(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:foot>
            <v-btn class="ma-1" @click="exportOwners">EXPORT</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/consts";

export default {
  name: "OwnersComponent",
  data() {
    return {
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
      dialog: false,
      dialogDelete: false,
      selected: [],                       // for export
      singleSelect: false,                // for export
      owner_id: '',
      edition: false,
      editedOwner: {
        name: '',
        description: ''
      },
      defaultOwner: {
        name: '',
        description: ''
      },
      tableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'description'
        },
        {
          text: 'Created',
          align: 'start',
          sortable: false,
          value: 'creation_info'
        },
        {
          text: 'Updated',
          align: 'start',
          sortable: false,
          value: 'update_info'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    formTitle() {
      return this.edition === false ? 'New Owner' : 'Edit Owner'
    },
    isOwnersLoadedFromDB() {
      return this.$store.getters.OWNERS_FROM_DB_LOADED
    },
    isAdmin() {
      return this.$store.getters.IS_ADMIN
    }
  },
  methods: {
    cancel() {
      // console.log('cancel')
      this.edition = false
      this.close()
    },
    save() {
      let user_logged = this.$store.getters.USER_LOGGED
      // console.log('save')
      if (!this.edition) {
        // console.log('saving the new owner')
        axios.post(API_BASE_URL + '/owners', this.editedOwner, {
          headers: {"Content-Type": "application/json;charset=UTF-8",
            "x-account": user_logged.name}
        })
            .then(() => {
              // console.log(response.data)
              this.$store.dispatch('loadOwnersFromDB')
              this.close()
              this.snackbar.color = "green"
              this.snackbar.timeout = 2500
              this.snackbar.text = "Owner successfully saved!"
              this.snackbar.error = ""
              this.snackbar.show = true
            })
            .catch(error => {
              // console.log('error -> ' + error)
              this.close()
              this.snackbar.color = "red"
              this.snackbar.timeout = 3500
              this.snackbar.text = "Oups! An error occurs! Owner not saved!"
              if (error.response.data !== undefined) {
                this.snackbar.error = "( " + error.response.data['msg'] + " )"
              }
              this.snackbar.show = true
            })
      } else {
        // console.log('saving the edited owner')
        // console.log('owner_id --> ' + this.owner_id)
        let owner = {
          "name": this.editedOwner['name'],
          "description": this.editedOwner['description'],
        }
        // console.log('owner --> ' + JSON.stringify(owner))
        axios.put(API_BASE_URL + '/owners/' + this.owner_id, owner, {
          headers: {"Content-Type": "application/json;charset=UTF-8",
            "x-account": user_logged.name}
        })
            .then(() => {
              this.owner_id = ''
              // console.log(response.data)
              this.edition = false
              this.$store.dispatch('loadOwnersFromDB')
              this.close()
              this.snackbar.color = "green"
              this.snackbar.timeout = 2500
              this.snackbar.text = "Owner successfully updated!"
              this.snackbar.error = ""
              this.snackbar.show = true
            })
            .catch(error => {
              this.edition = false
              this.owner_id = ''
              // console.log('error -> ' + error)
              this.close()
              this.snackbar.color = "red"
              this.snackbar.timeout = 3500
              this.snackbar.text = "Oups! An error occurs! Owner not updated!"
              if (error.response.data !== undefined) {
                this.snackbar.error = "( " + error.response.data['msg'] + " )"
              }
              this.snackbar.show = true
            })
      }
    },
    close() {
      this.clearEditedOwner()
      this.dialog = false
    },
    editOwner(item) {
      this.edition = true
      // console.log(item)
      this.owner_id = item['_id']['$oid']
      this.editedOwner = Object.assign({}, item)
      this.dialog = true
      this.$nextTick(()=> {
        const elt = this.$refs.txtOwnerName
        setTimeout(()=> {
          elt.focus()
        })
      })
    },
    deleteOwner(item) {
      this.owner_id = item['_id']['$oid']
      this.dialogDelete = true
    },
    closeDelete() {
      this.owner_id = ''
      this.dialogDelete = false
    },
    deleteOwnerConfirm() {
      console.log("owner id selected --> " + this.owner_id)
      axios.delete(API_BASE_URL + '/owners/' + this.owner_id)
          .then(() => {
            this.owner_id = ''
            // console.log('owner successfully deleted!')
            // console.log(response.data)
            this.$store.dispatch('loadOwnersFromDB')    // reset the list of owners
            this.$store.dispatch('loadItemsFromDB')     // reset the list of items
            this.closeDelete()
            this.snackbar.color = "green"
            this.snackbar.timeout = 2500
            this.snackbar.text = "Owner successfully deleted!"
            this.snackbar.error = ""
            this.snackbar.show = true
          })
          .catch(error => {
            this.owner_id = ''
            // console.log('error --> ' + error)
            this.closeDelete()
            this.snackbar.color = "red"
            this.snackbar.timeout = 2500
            this.snackbar.text = "Oups! An error occurs! Owner not deleted!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    },
    clearEditedOwner() {
      this.editedOwner = {
        name: '',
        description: ''
      }
    },
    exportOwners() {
      axios.post(API_BASE_URL + '/admin/export', JSON.stringify(this.selected), {
        headers: {"Content-Type": "application/json;charset=UTF-8"}
      }).then((response) => {
        let f = new Blob([response.data], {type: 'text/csv'})
        let a = document.createElement('a')
        let current_date = new Date().toISOString()
        a.download = current_date + '_owners.csv'
        a.href = window.URL.createObjectURL(f)
        a.click()
      }).catch(error => {
        this.snackbar.color = "red"
        this.snackbar.timeout = 3500
        this.snackbar.text = "Oups! An error occurs! unable to export data!"
        if (error.response.data !== undefined) {
          this.snackbar.error = "( " + error.response.data['msg'] + " )"
        }
        this.snackbar.show = true
      })
    }
  },
  mounted() {
    if (!this.isOwnersLoadedFromDB) {
      this.$store.dispatch('loadOwnersFromDB')
    }
  }
}
</script>

<style scoped>

</style>