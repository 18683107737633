<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-center">
      <h2>Map</h2>
    </div>
    <div>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        <div class="d-flex flex-column align-center">
          <span>{{ snackbar.text }}</span>
          <span>{{ snackbar.error }}</span>
        </div>
      </v-snackbar>
    </div>
    <v-layout justify-center>
      <v-container class="d-flex row map-container">
        <l-map ref="map" :zoom="zoom" :center="center" @ready="map_ready()">
          <l-tile-layer :url="url" :key="name" :name="name" :visible="true" layer-type="base"></l-tile-layer>
          <l-control-layers
              position="topright"
              :collapsed="true"
              :sort-layers="true"
          />
          <l-layer-group>
            <l-wms-tile-layer :visible="tileProvider1.visible" :name="tileProvider1.name"
                              :base-url="tileProvider1.baseUrl" :layers="tileProvider1.layers"
                              :key="tileProvider1.name" layer-type="overlay" :transparent="tileProvider1.transparent" :format="tileProvider1.format">
            </l-wms-tile-layer>
            </l-layer-group>
          <l-layer-group>
            <l-wms-tile-layer :visible="tileProvider2.visible" :name="tileProvider2.name"
                              :base-url="tileProvider2.baseUrl" :layers="tileProvider2.layers"
                              :key="tileProvider2.name" layer-type="overlay" :transparent="tileProvider2.transparent" :format="tileProvider2.format">
            </l-wms-tile-layer>
          </l-layer-group>
          <l-layer-group>
            <l-wms-tile-layer :visible="tileProvider3.visible" :name="tileProvider3.name"
                              :base-url="tileProvider3.baseUrl" :layers="tileProvider3.layers"
                              :key="tileProvider3.name" layer-type="overlay" :transparent="tileProvider3.transparent" :format="tileProvider3.format">
            </l-wms-tile-layer>
          </l-layer-group>
          <l-layer-group>
            <l-wms-tile-layer :visible="tileProvider4.visible" :name="tileProvider4.name"
                              :base-url="tileProvider4.baseUrl" :layers="tileProvider4.layers"
                              :key="tileProvider4.name" layer-type="overlay" :transparent="tileProvider4.transparent" :format="tileProvider4.format">
            </l-wms-tile-layer>
          </l-layer-group>
          <l-layer-group>
            <l-wms-tile-layer :visible="tileProvider5.visible" :name="tileProvider5.name"
                              :base-url="tileProvider5.baseUrl" :layers="tileProvider5.layers"
                              :key="tileProvider5.name" layer-type="overlay" :transparent="tileProvider5.transparent" :format="tileProvider5.format">
            </l-wms-tile-layer>
          </l-layer-group>
          <l-circle-marker v-for="manhole in manholes" :key="manhole['_id']['$oid']"
                           :lat-lng="[manhole.latitude, manhole.longitude]"
                           :radius="6"
                           :color="manhole.color">
            <l-tooltip :content="getManholeInfo(manhole)"></l-tooltip>
          </l-circle-marker>
        </l-map>
      </v-container>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/consts";

export default {
  name: "MapComponent",
  data() {
    return {
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
      map: null,                    // ref to the leaflet map
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      name: 'openstreep map',
      zoom: 10,
      center: [49.7263, 6.1221],
      bounds: null,
      manholes: [],
      color: "red",
      color2: "blue",
      tileProvider1: {
        baseUrl: 'https://wms.inspire.geoportail.lu/geoserver/us/wms',
        layers: 'US.Telecommunications_Appurtenance_Antenna',
        name: "Telecommunications - Appurtenance Antenna",
        visible: false,
        transparent: true,
        format: 'image/png'
      },
      tileProvider2: {
        baseUrl: 'https://wms.inspire.geoportail.lu/geoserver/us/wms',
        layers: 'US.ElectricityNetwork_Appurtenance_TransformerPoint',
        name: "Electricity Network - Appurtenance Transformer Point",
        visible: false,
        transparent: true,
        format: 'image/png'
      },
      tileProvider3: {
        baseUrl: 'https://wms.inspire.geoportail.lu/geoserver/us/wms',
        layers: 'US.ElectricityNetwork_ActivityComplex_ElectricityStation',
        name: "Electricity Network - Electricity station",
        visible: false,
        transparent: true,
        format: 'image/png'
      },
      tileProvider4: {
        baseUrl: 'https://wms.inspire.geoportail.lu/geoserver/us/wms',
        layers: 'US.ElectricityNetwork_Pole',
        name: "Electricity Network - Pole",
        visible: false,
        transparent: true,
        format: 'image/png'
      },
      tileProvider5: {
        baseUrl: 'https://wms.inspire.geoportail.lu/geoserver/us/wms',
        layers: 'US.ElectricityNetwork_UtilityLink',
        name: "Electricity Network - Utility link",
        visible: false,
        transparent: true,
        format: 'image/png'
      }
    }
  },
  methods: {
    map_ready() {
      this.$nextTick(() => {
        this.map = this.$refs.map.mapObject;
      })
    },
    getManholeInfo(item) {
      return "<p>name: " + item.name  + "</p>" + "<p>pk:" + item.pk  + "</p>" +
          "<p>estimated: " + item.estimated +
          "<p>load state: " + item.load_state +
          "<p>side IN:" + item.sides[0]['load_state'] + "</p>" +
          "<p>side OUT:" + item.sides[1]['load_state'] + "</p>" +
          "<p>side LEFT:" + item.sides[2]['load_state'] + "</p>" +
          "<p>side RIGHT:" + item.sides[3]['load_state'] + "</p>"
    },
    loadManholesForMap() {
      axios.get(API_BASE_URL + '/manholes/map')
          .then(response => {
            // console.log('response: ' + JSON.stringify(response.data))
            this.manholes = response.data
          })
          .catch(error => {
            this.snackbar.color = "red"
            this.snackbar.timeout = 3500
            this.snackbar.text = "Oups! An error occurs! Unable to retrieve manholes from datastore!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    }
  },
  mounted() {
    this.loadManholesForMap()
  }
}
</script>

<style scoped>

</style>