<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-center">
      <h2>Paths</h2>
    </div>
    <div>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        <div class="d-flex flex-column align-center">
          <span>{{ snackbar.text }}</span>
          <span>{{ snackbar.error }}</span>
        </div>
      </v-snackbar>
    </div>
    <v-container fluid>
      <v-layout class="d-flex flex-row">
        <v-layout class="d-flex flex-column">
          <v-container>
            <v-row>
              <v-col cols="7">
                <v-select v-model="selected_highway_start" :item-text="getHighwayName"
                :items="highways" label="Select the highway" @change="set_selected_highway_start"
                dense return-object :item-key="getKey">
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                </v-select>
                <v-select v-model="selected_direction_start" :item-text="getDirectionCode"
                          :items="directions_start" label="Select the direction" @change="set_selected_direction_start"
                          dense return-object :item-key="getKey">
                  <template v-slot:item="{ item }">
                    {{ item.code }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.code }}
                  </template>
                </v-select>
                <v-select v-model="selected_manhole_start" :item-text="getManholePK"
                          :items="manholes_start" label="Select the manhole" @change="set_selected_manhole_start"
                          dense return-object :item-key="getKey">
                </v-select>
              </v-col>
              <v-col cols="7">
                <v-select v-model="selected_highway_end" :item-text="getHighwayName"
                          :items="highways" label="Select the highway" @change="set_selected_highway_end"
                          dense return-object :item-key="getKey">
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                </v-select>
                <v-select v-model="selected_direction_end" :item-text="getDirectionCode"
                          :items="directions_end" label="Select the direction" @change="set_selected_direction_end"
                          dense return-object :item-key="getKey">
                  <template v-slot:item="{ item }">
                    {{ item.code }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.code }}
                  </template>
                </v-select>
                <v-select v-model="selected_manhole_end" :item-text="getManholePK"
                          :items="manholes_end" label="Select the manhole" @change="set_selected_manhole_end"
                          dense return-object :item-key="getKey">
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-btn color="primary" @click="search" :disabled="enable_search">SEARCH</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn color="primary" @click="clear">CLEAR</v-btn>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-container v-if="getResults">
              <div>
                <v-data-table v-model="selected" :headers="resultsHeaders" :items="results" item-key="id" :items-per-page="-1"
                              captions="results" dense :single-select="singleSelect" show-select :footer-props="footerProps">
                  <template v-slot:item.estimated="{ item }">
                    <v-chip
                        :color="setColorEstimatedValue(item)"
                        dark
                        label
                    >
                      {{ formatEstimatedValue(item) }}
                    </v-chip>
                  </template>
                  <template v-slot:item.load_state="{ item }">
                    <v-chip
                        :color="getLoadStateColor(item)"
                        dark
                        label
                    >
<!--                      {{ item.load_state }}-->
                      {{ formatLoadState(item.load_state) }}
                    </v-chip>
                  </template>
                  <template v-slot:item.interp_value="{ item }">
                    <v-chip
                        :color="getInterpValueColor(item)"
                        dark
                        label
                    >
                      {{ formatLoadState(item.interp_value) }}
<!--                      {{ item.interp_value }}-->
                    </v-chip>
                  </template>
                  <template v-slot:item.obstacles="{ item }">
                    {{ getComment(item) }}
                  </template>
                  <template v-slot:foot>
                    <v-btn class="ma-1" @click="exportInterpolation">EXPORT</v-btn>
                  </template>
                </v-data-table>
              </div>
            </v-container>
          </v-container>
          <v-container>
            <v-layout class="d-flex flex-row">
              <canvas id="myChart"></canvas>
            </v-layout>
          </v-container>
        </v-layout>
        <v-container class="map-container-path">
          <l-map ref="map_path" :zoom="zoom" :center="center" @ready="map_ready()">
            <l-tile-layer :url="url"></l-tile-layer>
            <l-control-layers
                position="topright"
                :collapsed="true"
                :sort-layers="true"
            />
            <l-layer-group>
              <l-wms-tile-layer :visible="tileProvider1.visible" :name="tileProvider1.name"
                                :base-url="tileProvider1.baseUrl" :layers="tileProvider1.layers"
                                :key="tileProvider1.name" layer-type="overlay" :transparent="tileProvider1.transparent" :format="tileProvider1.format">
              </l-wms-tile-layer>
            </l-layer-group>
            <l-layer-group>
              <l-wms-tile-layer :visible="tileProvider2.visible" :name="tileProvider2.name"
                                :base-url="tileProvider2.baseUrl" :layers="tileProvider2.layers"
                                :key="tileProvider2.name" layer-type="overlay" :transparent="tileProvider2.transparent" :format="tileProvider2.format">
              </l-wms-tile-layer>
            </l-layer-group>
            <l-layer-group>
              <l-wms-tile-layer :visible="tileProvider3.visible" :name="tileProvider3.name"
                                :base-url="tileProvider3.baseUrl" :layers="tileProvider3.layers"
                                :key="tileProvider3.name" layer-type="overlay" :transparent="tileProvider3.transparent" :format="tileProvider3.format">
              </l-wms-tile-layer>
            </l-layer-group>
            <l-layer-group>
              <l-wms-tile-layer :visible="tileProvider4.visible" :name="tileProvider4.name"
                                :base-url="tileProvider4.baseUrl" :layers="tileProvider4.layers"
                                :key="tileProvider4.name" layer-type="overlay" :transparent="tileProvider4.transparent" :format="tileProvider4.format">
              </l-wms-tile-layer>
            </l-layer-group>
            <l-layer-group>
              <l-wms-tile-layer :visible="tileProvider5.visible" :name="tileProvider5.name"
                                :base-url="tileProvider5.baseUrl" :layers="tileProvider5.layers"
                                :key="tileProvider5.name" layer-type="overlay" :transparent="tileProvider5.transparent" :format="tileProvider5.format">
              </l-wms-tile-layer>
            </l-layer-group>
            <l-circle-marker v-for="manhole in results" :key="manhole['_id']['$oid']"
            :lat-lng="[manhole.lat, manhole.lon]"
            :radius="6"
            :color="manhole.color">
              <l-tooltip :content="getManholeInfo(manhole)"></l-tooltip>
            </l-circle-marker>
            <l-polyline v-for="polyline in polylines" :lat-lngs="[[polyline['start'][0], polyline['start'][1]],[polyline['end'][0], polyline['end'][1]]]"
                        :color="polyline.color"
                        :key="polyline.id"></l-polyline>
          </l-map>
        </v-container>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/consts";
import Chart from 'chart.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: "PathComponent",
  data() {
    return {
      snackbar: {
        show: false,
        color: '',
        text: '',
        error: '',
        timeout: 2000
      },
      selected: [],                       // for export
      singleSelect: false,                // for export
      map_path: null,                    // ref to the leaflet map
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 10,
      center: [49.7263, 6.1221],
      bounds: null,
      highways: [],
      manholes: [],
      selected_highway_start: {},
      selected_highway_end: {},
      selected_direction_start: {},
      selected_direction_end: {},
      directions_start: [],
      directions_end: [],
      selected_manhole_start: {},
      selected_manhole_end: {},
      manholes_start: [],
      manholes_end: [],
      resultsHeaders: [
        {
          text: 'PK',
          align: 'start',
          sortable: false,
          value: 'pk'
        },
        {
          text: 'Highway',
          align: 'start',
          sortable: false,
          value: 'direction.highway.name'
        },
        {
          text: 'direction',
          align: 'start',
          sortable: false,
          value: 'direction.code'
        },
        {
          text: 'Estimation load state?',
          align: 'center',
          sortable: true,
          value: 'estimated'
        },
        {
          text: 'Load state',
          align: 'start',
          sortable: false,
          value: 'load_state'
        },
        {
          text: 'Interp load state',
          align: 'start',
          sortable: false,
          value: 'interp_value'
        },
        {
          text: 'Comment',
          align: 'start',
          sortable: false,
          value: 'obstacles'
        }
      ],
      results: [],
      hasResults: false,
      interp_data: [],
      ctx: null,
      myChart: null,
      graphData: {
        type: 'bar',
        data: this.interp_data
      },
      polylines: [],
      tileProvider1: {
        baseUrl: 'https://wms.inspire.geoportail.lu/geoserver/us/wms',
        layers: 'US.Telecommunications_Appurtenance_Antenna',
        name: "Telecommunications - Appurtenance Antenna",
        visible: false,
        transparent: true,
        format: 'image/png'
      },
      tileProvider2: {
        baseUrl: 'https://wms.inspire.geoportail.lu/geoserver/us/wms',
        layers: 'US.ElectricityNetwork_Appurtenance_TransformerPoint',
        name: "Electricity Network - Appurtenance Transformer Point",
        visible: false,
        transparent: true,
        format: 'image/png'
      },
      tileProvider3: {
        baseUrl: 'https://wms.inspire.geoportail.lu/geoserver/us/wms',
        layers: 'US.ElectricityNetwork_ActivityComplex_ElectricityStation',
        name: "Electricity Network - Electricity station",
        visible: false,
        transparent: true,
        format: 'image/png'
      },
      tileProvider4: {
        baseUrl: 'https://wms.inspire.geoportail.lu/geoserver/us/wms',
        layers: 'US.ElectricityNetwork_Pole',
        name: "Electricity Network - Pole",
        visible: false,
        transparent: true,
        format: 'image/png'
      },
      tileProvider5: {
        baseUrl: 'https://wms.inspire.geoportail.lu/geoserver/us/wms',
        layers: 'US.ElectricityNetwork_UtilityLink',
        name: "Electricity Network - Utility link",
        visible: false,
        transparent: true,
        format: 'image/png'
      },
      footerProps: {'items-per-page-options': [20, 50, 100, 500, 1000, -1]},
    }
  },
  computed: {
    getResults() {
      return this.hasResults
    },
    enable_search() {
      return this.selected_manhole_start['_id'] === undefined || this.selected_manhole_end['_id'] === undefined
    }
  },
  methods: {
    search() {
      let data = {
        "id_manhole_start": this.selected_manhole_start['_id']['$oid'],
        "id_manhole_end": this.selected_manhole_end['_id']['$oid']
      }
      // console.log('data: ' + JSON.stringify(data))
      axios.post(API_BASE_URL + "/tools/path1", data, {
        headers: {"Content-Type": "application/json;charset=UTF-8"}
      }).then(response => {
        this.results = response.data
        this.hasResults = true
        this.createPolylines(this.results)
        this.createGraphDataSet(this.results)
      }).catch(error => {
        console.log("error --> " + error)
        this.hasResults = false
      })
    },
    getComment(item) {
      // console.log(item)
      let comment = ''
      if (item['obstacles']['after']['type'] === 'tunnel') {
        if (comment === '') comment = "tunnel after"
        else comment = comment + " and " + " tunnel after"
      }
      if (item['obstacles']['before']['type'] === 'tunnel') {
        if (comment === '') comment = "tunnel before"
        else comment = comment + " and " + " tunnel before"
      }
      if (item['obstacles']['before']['type'] === 'bridge') {
        if (comment === '') comment = "bridge before"
        else comment = comment + " and " + " bridge before"
      }
      if (item['obstacles']['after']['type'] === 'bridge') {
        if (comment === '') comment = "bridge after"
        else comment = comment + " and" + " bridge after"
      }
      if (item['highway_bridge'] !== undefined && item['highway_bridge'].length > 0) {
        if (comment === '') comment = "cross highway"
        else comment = comment + " and" + " cross highway"
      }
      return comment
    },
    getLoadStateColor(item) {
      if (item.load_state === null) return "white"
      let value = parseFloat(item.load_state)
      if (value > 0.75) return "red"
      else if (value > 0.5 && value <= 0.75) return "orange"
      else return "green"
    },
    getInterpValueColor(item) {
      if (item.interp_value === null) return "white"
      let value = parseFloat(item.interp_value)
      if (value > 0.75) return "red"
      else if (value > 0.5 && value <= 0.75) return "orange"
      else return "green"
    },
    getKey(item) {
      return item['_id']['$oid']
    },
    loadManholesFromDBForPath() {
      axios.get(API_BASE_URL + '/tools/manholes')
          .then(response => {
            // console.log('response: ' + JSON.stringify(response.data))
            this.manholes = response.data
          })
          .catch(error => {
            this.snackbar.color = "red"
            this.snackbar.timeout = 3500
            this.snackbar.text = "Oups! An error occurs! Unable to retrieve manholes from datastore!"
            if (error.response.data !== undefined) {
              this.snackbar.error = "( " + error.response.data['msg'] + " )"
            }
            this.snackbar.show = true
          })
    },
    loadHighways() {
      axios.get(API_BASE_URL + '/highways')
      .then(response => {
        this.highways = response.data
      })
      .catch(error => {
        this.snackbar.color = "red"
        this.snackbar.timeout = 3500
        this.snackbar.text = "Oups! An error occurs! Unable to retrieve highways from datastore!"
        if (error.response.data !== undefined) {
          this.snackbar.error = "( " + error.response.data['msg'] + " )"
        }
        this.snackbar.show = true
      })
    },
    loadmanholesForADirection(direction_id, who) {
      axios.get(API_BASE_URL + '/manholes/direction/' + direction_id)
      .then(response => {
        if (who==='start') this.manholes_start = response.data
        else this.manholes_end = response.data
      })
      .catch(error => {
        this.snackbar.color = "red"
        this.snackbar.timeout = 3500
        this.snackbar.text = "Oups! An error occurs! Unable to retrieve manholes for this direction from datastore!"
        if (error.response.data !== undefined) {
          this.snackbar.error = "( " + error.response.data['msg'] + " )"
        }
        this.snackbar.show = true
      })
    },
    map_ready() {
      this.$nextTick(() => {
        this.map_path = this.$refs.map_path.mapObject;
      })
    },
    // showManhole(item) {
    //   return item.direction.highway.name + "--" + item.direction.code + "--" + item.pk
    // },
    getHighwayName(item) {
      if (item!==undefined) return item.name
      else return "no data"
    },
    getDirectionCode(item) {
      if (item!==undefined) return item.code
      else return "no data"
    },
    set_selected_highway_start(item) {
      // console.log(JSON.stringify(item))
      this.directions_start = item.directions
      // console.log('directions --> ' + JSON.stringify(this.directions_start))
    },
    set_selected_highway_end(item) {
      this.directions_end = item.directions
    },
    set_selected_direction_start(item) {
      this.selected_direction_start = item
      // load manholes for this direction
      // console.log('direction id: ' + this.selected_direction_start['_id']['$oid'])
      this.loadmanholesForADirection(this.selected_direction_start['_id']['$oid'], "start")
    },
    set_selected_direction_end(item) {
      this.selected_direction_end = item
      // load manholes for this direction
      // console.log('direction id: ' + this.selected_direction_start['_id']['$oid'])
      this.loadmanholesForADirection(this.selected_direction_end['_id']['$oid'], "end")
    },
    set_selected_manhole_start(item) {
      this.selected_manhole_start = item
    },
    set_selected_manhole_end(item) {
      this.selected_manhole_end = item
    },
    getManholePK(item) {
      if (item!==undefined) return item.pk
      else return "no data"
    },
    initChart(chartId, chartData) {
      this.ctx = document.getElementById(chartId)
      this.myChart = new Chart(this.ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      })
    },
    clear() {
      this.selected_highway_start = {}
      this.selected_highway_end = {}
      this.selected_manhole_start = {}
      this.selected_manhole_end = {}
      this.selected_direction_start = {}
      this.selected_direction_end = {}
      this.directions_start = []
      this.directions_end = []
      this.results = []
      this.polylines = []
      this.myChart.data.labels = []
      this.myChart.data.datasets = []
      this.myChart.update()
      this.hasResults = false
    },
    getManholeInfo(item) {
      return "<p>name: " + item.name  + "</p>" + "<p>pk:" + item.pk  + "</p>" +
          "<p>estimated: " + item.estimated +
          "<p>load state: " + item.load_state +
          "<p>interp load state: " + item.interp_value
    },
    createPolylines(polylines) {
      for (let i = 0; i < polylines.length-1; i++) {
        let value = parseFloat(polylines[i+1].interp_value)
        let color='white'
        if (value > 0.75) color = "red"
        else if (value > 0.5 && value <= 0.75) color= "orange"
        else color= "green"
        let polyline = {
          'id': uuidv4(),
          'start': [
            polylines[i]['lat'],
            polylines[i]['lon']
          ],
          'end': [
            polylines[i+1]['lat'],
            polylines[i+1]['lon']
          ],
          'color': color
        }
        // console.log('polyline: ' + JSON.stringify(polyline))
        this.polylines.push(polyline)
      }
    },
    exportInterpolation() {
      axios.post(API_BASE_URL + '/admin/export', JSON.stringify(this.selected), {
        headers: {"Content-Type": "application/json;charset=UTF-8"}
      }).then((response) => {
        let f = new Blob([response.data],  {type: 'text/csv'})
        let a = document.createElement('a')
        let current_date = new Date().toISOString()
        a.download = current_date + '_interpolation.csv'
        a.href = window.URL.createObjectURL(f)
        a.click()
      }).catch(error => {
        this.snackbar.color = "red"
        this.snackbar.timeout = 3500
        this.snackbar.text = "Oups! An error occurs! unable to export data!"
        if (error.response.data !== undefined) {
          this.snackbar.error = "( " + error.response.data['msg'] + " )"
        }
        this.snackbar.show = true
      })
    },
    createGraphDataSet(res) {
      let data_intrep = []
      let data_estim = []
      let labels = []
      let backgrd_interp = []
      let backgrd_load_state = []
      for (let i = 0; i < res.length; i++) {
        let value = parseFloat(res[i].interp_value)
        let value2 = parseFloat(res[i].load_state)
        data_intrep.push(value)
        data_estim.push(value2)
        labels.push(res[i].pk)
        backgrd_interp.push('rgba(255, 99, 132, 0.2)')
        backgrd_load_state.push('rgba(54, 162, 235, 0.2)')
      }

      let datasets = [{
        label: 'Interpolation values',
        barPercentage: 0.5,
        barThickness: 8,
        maxBarThickness: 12,
        minBarLength: 5,
        data: data_intrep,
        backgroundColor: backgrd_interp
      },
        {
          label: 'Load state values',
          barPercentage: 0.5,
          barThickness: 8,
          maxBarThickness: 12,
          minBarLength: 5,
          data: data_estim,
          backgroundColor: backgrd_load_state
        }]
      this.interp_data = {
        'labels': labels,
        'datasets': datasets
      }
      this.myChart.data.labels = labels
      this.myChart.data.datasets = datasets
      this.myChart.update()
    },
    formatEstimatedValue(item) {
      if (item.estimated) {
        return 'Y'
      }
      else return 'N'
    },
    setColorEstimatedValue(item) {
      if (item.estimated) {
        return "green"
      }
      else return "red"
    },
    formatLoadState(item) {
      return Number.parseFloat(item).toFixed(5)
    }
  },
  mounted() {
    this.loadHighways()
    this.loadManholesFromDBForPath()
    this.initChart('myChart', this.graphData)
  }
}
</script>

<style scoped>

</style>